import React from 'react';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";

function ProductDetails(props) {
  return (
    <React.Fragment>
        <div className="container">
            <TableContainer>
                <TableHead style={{backgroundColor: '#6ab43e'}}>
                    <TableRow>
                        <TableCell>

                        </TableCell>
                    </TableRow>
                </TableHead>
            </TableContainer>
        </div>
    </React.Fragment>
  )
}

export default ProductDetails

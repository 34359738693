import React from 'react'
import { NavLink } from 'react-router-dom'
import Enquiry from './sidebar/Enquiry'
import Rightsidebar from './sidebar/Rightsidebar'

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";

export default function Lldpe() {
    return (
        <div>

            {/* <!-- Page Banner Area Start --> */}
            <div class="page-banner-area section overlay gradient">
                <div class="container">
                    <div class="row">
                        <div class="page-banner col-12">
                            <h2>product</h2>
                            <ul>
                                <li><NavLink to="/">Home</NavLink></li>
                                <li><NavLink to="product">Products</NavLink></li>
                                <li><NavLink to="/polythylene-granules">Polythylene Granules</NavLink></li>
                                <li><NavLink to="/lldpe-granules">LLDPE Granules</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Page Banner Area End --> */}

            {/* <!-- Contact Area Start --> */}
            <div id="project-area" class="project-area section pt-120 pb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-9 col-lg-8 col-12 mb-50">
                            <div class="single-project-details">
                                <img class="main-image" src="assets/img/products/lldpe.jpg" alt="" />
                                {/* <h3 class="title">LDPE Granules</h3>
                               
                                <p>We are engaged in offering very soft and pliable LDPE Granules for various applications. Also called thermoplastic, LDPE is a substantially linear polymer (polyethylene) made from petroleum. Moreover, our company supplies optimum quality Low Density Polyethylene Granules at market leading rates.</p>
                                
                                <h3 class="title">About HDPE</h3>
                                <ol>
                                    <li>LDPE Granules Low-density polyethylene (LDPE) is an easily recycled, versatile thermoplastic</li>
                                    <li>LDPE is formed into thin films for use as grocery bags, food wrapping material, power cable sheathing and worktable coating</li>
                                    <li>Laboratory containers used in chemical research are often made from primarily LDPE</li>
                                </ol>

                                <h3 class="title">LDPE Resistance</h3>
                                <ol>
                                    <li>Excellent resistance (no attack) to dilute and concentrated Acids, Alcohols and Bases</li>
                                    <li>Good resistance (minor attack) to Aldehydes, Esters, Aliphatic and Aromatic Hydrocarbons, Ketones and Mineral and Vegetable Oils</li>
                                    <li>Limited resistance (moderate attack and suitable for short term use only) to Halogenated Hydrocarbons and Oxidizing Agents</li>
                                    <li>Poor resistance and not recommended for use with Halogenated Hydrocarbons</li>
                                </ol>

                                <h3 class="title">LDPE Fabrication</h3>
                                <ol>
                                    <li>Films & Liners</li>
                                    <li>Tarpaulins</li>
                                    <li>Trays & containers</li>
                                    <li>Corrosion resistant work surfaces</li>
                                    <li>Carry Bags</li>
                                    <li>Good for parts that require flexibility</li>
                                    <li>CFood storage and laboratory</li>
                                </ol>

                                <h3 class="title">Quick Facts</h3>
                                <ol>
                                    <li>Maximum Temperature : 176°F 80°C</li>
                                    <li>Autoclavable : No</li>
                                    <li>Tensile Strength : 1700 psi</li>
                                    <li>UV Resistance : Poor</li>
                                    <li>Excellent flexibility</li>
                                    <li>Minimum Temperature : -58°F - -50°C</li>
                                    <li>Melting Point : 248°F 120°C</li>
                                    <li>Hardness : SD55</li>
                                    <li>Translucent</li>
                                    <li>Specific Gravity : 0.92</li>
                                </ol> */}

                                <h3 class="title" style={{ textAlign: 'center', paddingBottom: '20px' }}>LLDPE Granules</h3>

                                <TableContainer>
                                    <Table >
                                        <TableHead style={{ backgroundColor: '#6ab43e' }}>
                                            <TableRow>
                                                <TableCell style={{ textAlign: 'center' }}>
                                                    Grade
                                                </TableCell >

                                                <TableCell style={{ textAlign: 'center' }}>
                                                    Category
                                                </TableCell>

                                                <TableCell style={{ textAlign: 'center' }}>
                                                    MFR
                                                </TableCell>

                                                <TableCell style={{ textAlign: 'center' }}>
                                                    Density
                                                </TableCell>

                                                <TableCell style={{ textAlign: 'center' }}>
                                                    Application
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>
                                                    DATASHEET
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    FB2230
                                                </TableCell>
                                                <TableCell>
                                                    LLDPE
                                                </TableCell>
                                                <TableCell>
                                                    0.25
                                                </TableCell>
                                                <TableCell>
                                                    923
                                                </TableCell>
                                                <TableCell>
                                                    Mono layer & co-extrusion films, Agriculture Film (incl. Greenhouse Film), Lamination (incl. Stand up Pouches), Shrink film Compression Packaging, Geomembrane, Heavy duty shipping sacks, Exclusive Carrier/Boutique bags, Industrial Film, Frozen Food Form Fill Seal (FFS), Packaging Impact modifier                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/FB2230.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    FB2310
                                                </TableCell>
                                                <TableCell>
                                                    LLDPE
                                                </TableCell>
                                                <TableCell>
                                                    0.2
                                                </TableCell>
                                                <TableCell>
                                                    931
                                                </TableCell>
                                                <TableCell>
                                                    Mono layer & co-extrusion films, Agriculture Film (incl. Greenhouse Film) Lamination (Inc. stand up Pouches) Shrink Film Compression packaging Security packaging, Heavy duty shipping sacks, Exclusive Carrier/ Boutique bags, Industrial Film, Frozen Food Form Fill Seal (FFS), Packaging Impact modifier                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/FB2310.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    FK1820
                                                </TableCell>
                                                <TableCell>
                                                    LLDPE
                                                </TableCell>
                                                <TableCell>
                                                    1.5
                                                </TableCell>
                                                <TableCell>
                                                    918
                                                </TableCell>
                                                <TableCell>
                                                    Heavy duty shipping sacks (HDSS) Lamination, Stretch Hood Liner Stand up Pouches, Frozen Food Packaging, Food Packaging Agriculture, Film Form Fill Seal (FFS), Artificial Grass Multilayer Packaging Film, Transparent Film, Lamitube Stretch Cling Film, Impact modifier, Protection Film
                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/FK1820.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    FK1828
                                                </TableCell>
                                                <TableCell>
                                                    LLDPE
                                                </TableCell>
                                                <TableCell>
                                                    1.5
                                                </TableCell>
                                                <TableCell>
                                                    918
                                                </TableCell>
                                                <TableCell>
                                                    Lamination Stretch Hood Food Packaging Liquid Packaging, Frozen Food Packaging, Stand up Pouches Agriculture Film, Lamitube Shrink Film, Multilayer Packaging Film, Stretch Hood Impact modifier
                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/FK1828.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    HE1490
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    0.35
                                                </TableCell>
                                                <TableCell>
                                                    948
                                                </TableCell>
                                                <TableCell>
                                                    Range of wall thickness drip irrigation tubing for online drip laterals and flat as well as cylindrical emitting pipe/inline production, Sprinkler Irrigation Pipes, Non Pressure Utility Pipes, Thin Walled Corrugated Pipes

                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/HE1490.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    HE3450
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    0.5
                                                </TableCell>
                                                <TableCell>
                                                    952
                                                </TableCell>
                                                <TableCell>
                                                    top-coat in 3 layer PE coating of steel pipes and is suitable for severe
                                                    laying conditions even at elevated ambient temperatures. With this material, high running speeds and relatively
                                                    thin layers are obtained without problems, can be used from -40 to +80°C design
                                                    temperature of the pipeline when combined with the grafted adhesives ME0410, ME0420 or ME0433.
                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/HE1490.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    HE3460
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    0.5
                                                </TableCell>
                                                <TableCell>
                                                    960
                                                </TableCell>
                                                <TableCell>
                                                    HE3460 is suitable material for non-pressure pipe applications such as gravity sewage, storm water pipes and conduits
                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/HE3460.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    HE3490LS
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    0.25
                                                </TableCell>
                                                <TableCell>
                                                    960
                                                </TableCell>
                                                <TableCell>
                                                    pressure pipe systems used in drinking water and natural gas, pressure sewerage, relining, sea outfall and industrial applications. It is especially designed for the production of larger diameter, thick wall pipe, but can be processed for the whole range of diameters. It also shows excellent resistance to rapid crack propagation and slow crack growth                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/HE3490LS.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    HE3490LS-H
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    0.25
                                                </TableCell>
                                                <TableCell>
                                                    960
                                                </TableCell>
                                                <TableCell>
                                                    pressure pipe systems used in drinking water and natural gas, pressure sewerage, relining, sea outfall and industrial applications, especially where they are to be installed in challenging conditions. It is especially designed for the production of larger diameter, thick wall pipe, but can be processed for the whole range of diameters                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/HE3490-LS-H.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    MB6561
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    1.5
                                                </TableCell>
                                                <TableCell>
                                                    955
                                                </TableCell>
                                                <TableCell>
                                                    Caps and closures for taste-sensitive beverages like water. Consumer and industrial articles with high ESCR demands.                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/MB6561.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    MB6562
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    1.5
                                                </TableCell>
                                                <TableCell>
                                                    955
                                                </TableCell>
                                                <TableCell>
                                                    Caps and closures for taste-sensitive beverages like water. Consumer and industrial articles with high ESCR demands.                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/MB6562.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    ME3440
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    0.85
                                                </TableCell>
                                                <TableCell>
                                                    954
                                                </TableCell>
                                                <TableCell>
                                                    pressure pipe systems in the applications field of drinking water, natural gas, pressure sewerage, relining, sea outfall and industrial, where flexibility and coilability is of importance. It also shows excellent resistance to rapid crack propagation and slow crack growth. Attributed to the bimodal molecular weight distribution structure, it gives outstanding extrudability, compared to conventional PE80 products.
                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/ME3440.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    ME3441
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    0.8
                                                </TableCell>
                                                <TableCell>
                                                    944
                                                </TableCell>
                                                <TableCell>
                                                    gas pressure pipe systems where flexibility and coilability is of importance. It also shows excellent resistance to rapid crack propagation and slow crack growth. Attributed to the molecular structure, it gives outstanding extrudability, compared to conventional PE80 products.
                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/ME3441.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    FK2715
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    1.3
                                                </TableCell>
                                                <TableCell>
                                                    927
                                                </TableCell>
                                                <TableCell>
                                                    Improved Stiffness Lamination Film, Collation Shrink Film, Stand up Pouch, Food Packaging, Lamitube, Heavy Duty bags, Multilayer Film
                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/FK2715.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    FM1810
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    1.1
                                                </TableCell>
                                                <TableCell>
                                                    918
                                                </TableCell>
                                                <TableCell>
                                                    Lamination, Food Packaging, Liquid Packaging, Impact Modifier, Agriculture Film, Multilayer Packaging Film
                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/FM1810.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>


                            </div>
                            <Enquiry />
                        </div>
                        <Rightsidebar />
                    </div>
                </div>
            </div>
            {/* <!-- Contact Area End --> */}
        </div>

    )
}

import React from 'react';
import { NavLink }from 'react-router-dom'
import Enquiry from './sidebar/Enquiry'
import Rightsidebar from './sidebar/Rightsidebar'



function Abs() {
  return (
      <React.Fragment>
          <React.Fragment>
            <div>
                {/* <!-- Page Banner Area Start --> */}
                <div class="page-banner-area section overlay gradient">
                    <div class="container">
                        <div class="row">
                            <div class="page-banner col-12">
                                <h2>product</h2>
                                <ul>
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><NavLink to="product">Products</NavLink></li>
                                    <li><NavLink to="/abs-granules">ABS Granules</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Page Banner Area End --> */}

                {/* Description Starts */}

                <div id="project-area" class="project-area section pt-120 pb-70">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-9 col-lg-8 col-12 mb-50">
                                <div class="single-project-details">
                                    <img class="main-image" src="assets/img/products/ABS.jpg" alt="" />
                                    <h3 class="title">ABS Granules</h3>
                                    {/* <div class="tags fix">
                                    <a href="#">Energy</a>
                                    <a href="#">Green</a>
                                    <a href="#">Solar</a>
                                </div> */}
                                    {/* <!--  Content  --> */}
                                    <p>We offer high quality ABS Granules in a wide variety. The ABS Granules have better solvent and high temperature resistance than impact polystyrenes. These Acrylonitrile Butadiene Styrene Granules possess various physical, mechanical, electrical and thermal properties apt for industrial applications. We offer Acrylonitrile Butadiene Styrene at the industry leading rates to esteemed clients.</p>

                                    <h3 class="title">About ABS</h3>
                                    <p>Acrylonitrile Butadiene Styrene (ABS) is formed by the polymerization of Acrylonitrile, Butadiene, and Styrene monomers. Chemically, this thermoplastic family of plastics is called "terpolymers", in that they involve the combination of three different monomers to form a single material that draws from the properties of all three. ABS possesses outstanding impact strength and high mechanical strength, which makes it so suitable for tough consumer products. Additionally, ABS has good dimensional stability and electrical insulating properties.</p>

                                    <h3 class="title">ABS Resistance</h3>
                                    <ol>
                                        <li>Excellent resistance (no attack) to Glycerin, Inorganic Salts, Alkalis, Many Acids, Most Alcohols and Hydrocarbons</li>
                                        <li>Limited resistance (moderate attack and suitable for short term use only) to Weak Acids</li>
                                        <li>Poor resistance (not recommended for use with) Strong Acids and Solvents, Ketones, Aldehydes, Esters, and some Chlorinated Hydrocarbons</li>
                                    </ol>

                                    <h3 class="title">ABS Fabrication</h3>
                                    <ol>
                                        <li>It can be thermo-formed, pressure formed, blow molded, sheared, sawed, drilled, or even "cold stamped"</li>
                                        <li>Joints can be ultrasonic welded, thermo-welded, and chemically bonded</li>
                                        <li>Impact resistant</li>
                                        <li>Commonly used for telephone bodies, safety helmets, piping, furniture, car components, TV casings, radios, control panels, and similar</li>
                                        <li>Valve bodies, material handling equipment</li>
                                    </ol>

                                    <h3 class="title">Attributes</h3>
                                    <ol>
                                        <li>High Rigidity</li>
                                        <li>High impact Resistance</li>
                                        <li>Easy Process Ability</li>
                                        <li>High Dimensional Stability</li>
                                       
                                    </ol>
                                    <h3 class="title">Usage</h3>
                                    <ol>
                                        <li>Computer Housing</li>
                                        <li>Miscellaneous Goods</li>
                                        <li>Automobile Components</li>
                                        <li>Light and Rigid Molded Products</li>
                                       
                                    </ol>

                                    <h3 class="title">Quick Facts</h3>
                                    <ol>
                                        <li>Maximum Temperature : 176°F 80°C</li>
                                        <li>Autoclavable : No</li>
                                        <li>Tensile Strength : 4300 psi</li>
                                        <li>UV Resistance : Poor</li>
                                        <li>Rigid</li>
                                        <li>Minimum Temperature : -4°F -20°C</li>
                                        <li>Melting Point : 221°F 105°C</li>
                                        <li>Hardness : R110</li>
                                        <li>Translucent</li>
                                        <li>Specific Gravity : 1.04</li>
                                    </ol>

                                </div>

                                {/* PRODUCT TYPE AREA */}
                                
                                <div id="project-area" class="project-area section pt-120 pb-90">
                                <h3 class="title">Types of ABS Granules</h3>

                                    <div class="container">
                                        <div class="row">
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/abs-natural.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">ABS Natural</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/* <div class="tags fix"> 
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                    </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/abs-cherry.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">ABS Cherry</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/*<div class="tags fix">
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                    </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/abs-black.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">ABS Black</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/*<div class="tags fix">
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/abs-red.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">ABS Red</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/*<div class="tags fix">
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/abs-grey.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">ABS Grey</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/*<div class="tags fix">
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/abs-grey.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">ABS Grey</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/*<div class="tags fix">
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/abs-milky.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">ABS Milky</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/*<div class="tags fix">
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            
                                            
                                            
                                           
                                           
                                            
                                        </div>
                                    </div>
                                </div>

                                <Enquiry />
                            </div>
                            <Rightsidebar />
                        </div>
                    </div>
                </div>

                
            </div>
        </React.Fragment>
      </React.Fragment>
  );
}

export default Abs;

import React from 'react';
import HeroSlider, { Slide, SideNav } from "hero-slider";
import Button from "react-png-button";
import Wrapper from "../ui/Wrapper/Wrapper";
import Title from "../ui/Title/Title";
import Subtitle from "../ui/Subtitle/Subtitle";

import AboutSection from "./AboutSection";

// Images
const salta = "assets/img/hero/15.jpg";
const scharbeutz = "assets/img/hero/16.jpg";

function Main() {
  console.log('Main==============>')
  return (
    <React.Fragment>

      {/* Hero Area Start */}
      <HeroSlider
          slidingAnimation="top_to_bottom"
          orientation="horizontal"
          initialSlide={1}
          onBeforeChange={(previousSlide, nextSlide) =>
              console.log("onBeforeChange", previousSlide, nextSlide)
          }
          onChange={nextSlide => console.log("onChange", nextSlide)}
          onAfterChange={nextSlide => console.log("onAfterChange", nextSlide)}
          style={{
            backgroundColor: "#000"
          }}
          settings={{
            slidingDuration: 600,
            slidingDelay: 100,
            shouldAutoplay: true,
            shouldDisplayButtons: true,
            autoplayDuration: 8000,
            height: "100vh"
          }}
      >
        <Slide
            background={{
              backgroundImage: salta
            }}
        >
          <Wrapper>
            <Title
              style={{
                color: "#96C74F"
              }}
            >WE Are Crystal Polymers</Title>
            <Subtitle>WE ARE PLASIC REPROCESSING UNIT</Subtitle>
            {/* <div>
              <Button
                  style={{
                    width: 100,
                    margin: "12px 8px"
                  }}
                  button="light"
              >
                Learn
              </Button>
              <Button
                  style={{
                    width: 100,
                    margin: "12px 8px"
                  }}
                  button="light"
              >
                Contact
              </Button>
            </div> */}
          </Wrapper>
        </Slide>

        <Slide
            background={{
              backgroundImage: scharbeutz
            }}
        >
          <Wrapper>
            <Title>WE CARE FOR ENVIRONMENT</Title>
            <Subtitle>PLASTIC RECYCLING HELP REDUCES PLASTIC WASTE FROM EARTH</Subtitle>
            {/* <div>
              <Button
                  style={{
                    width: 100,
                    margin: "12px 8px"
                  }}
                  button="light"
              >
                Learn
              </Button>
              <Button
                  style={{
                    width: 100,
                    margin: "12px 8px"
                  }}
                  button="light"
              >
                Contact
              </Button>
            </div> */}
          </Wrapper>
        </Slide>

        <SideNav
            position={{
              top: 0,
              right: 0
            }}
        />
      </HeroSlider>
      {/* Hero Area End */}

      <AboutSection />

      {/* Donation Area Start */}
      {/* <div id="donation-area" className="donation-area section bg-gray pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="donation-title-2">
                <h3>NEED URGENT DONATION FOR</h3>
                <h1>PROTECT &amp; CARE OUR ENVIRONMENT</h1>
              </div>
              <div className="donation-form-2">
                <form action="#" id="donation-form-2">
                  <div className="row mb-n4">
                    <div className="col-lg-4 col-md-6 col-12 mb-4">
                      <input type="text" placeholder="YOUR NAME" />
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 mb-4">
                      <input type="email" placeholder="EMAIL" />
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 mb-4">
                      <input type="text" placeholder="PHONE" />
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 mb-4">
                      <select>
                        <option value={1}>SELECT PROJECT</option>
                        <option value={2}>PROJECT NUMBER 1</option>
                        <option value={3}>PROJECT NUMBER 2</option>
                        <option value={4}>PROJECT NUMBER 3</option>
                        <option value={5}>PROJECT NUMBER 4</option>
                        <option value={6}>PROJECT NUMBER 4</option>
                      </select>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 mb-4">
                      <select>
                        <option value={1}>SELECT AMOUNT</option>
                        <option value={2}>$1000</option>
                        <option value={3}>$1500</option>
                        <option value={4}>$2000</option>
                        <option value={5}>$2500</option>
                        <option value={6}>$3000</option>
                      </select>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 mb-4"><input type="submit" defaultValue="donate now" /></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Donation Area End */}


      {/* Service Area Start */}
      <div id="service-area" className="service-area section pt-120 pb-120">
        <div className="container">
          {/* Section Title */}
          <div className="row">
            <div className="section-title text-center col-12 mb-80">
              <h2>OUTLINE</h2>
            </div>
          </div>
          <div className="row">
            {/*  Single Service  */}
            <div className="single-service text-center col-lg-3 col-md-6 col-12">
              <img src="assets/img/service/1.png" alt="" />
              <h2>5+</h2>
              <p>Years Of Experience</p>
            </div>
            {/*  Single Service  */}
            <div className="single-service text-center col-lg-3 col-md-6 col-12">
              <img src="assets/img/service/2.png" alt="" />
              <h2>20+</h2>
              <p>Machinery</p>
            </div>
            {/*  Single Service  */}
            <div className="single-service text-center col-lg-3 col-md-6 col-12">
              <img src="assets/img/service/3.png" alt="" />
              <h2>300+</h2>
              <p>Fullfilled Clients</p>
            </div>
            {/*  Single Service  */}
            <div className="single-service text-center col-lg-3 col-md-6 col-12">
              <img src="assets/img/service/4.png" alt="" />
              <h2>200+</h2>
              <p>Applications</p>
            </div>
          </div>
        </div>
      </div>
      {/* Service Area End */}


      {/* Video Area Start */}
      {/* <div id="video-area" className="video-area section overlay pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="video-wrapper col-12 text-center">
              <h1>WATCH OUR LATEST CAMPAIGN VIDEO</h1>
              <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of clssical Latin literature from 45 BC, making it over 2000 years old.</p>
              <a className="video-popup" href="https://www.youtube.com/watch?v=mcixldqDIEQ"><i className="zmdi zmdi-play-circle-outline" /></a>
            </div>
          </div>
        </div>
      </div> */}
      {/* Video Area End */}

      {/* Project Area Start */}
      <div id="project-area" className="project-area section pt-120 pb-90">
        <div className="container">
          {/* Section Title */}
          <div className="row">
            <div className="section-title text-center col-12 mb-80">
              <h2>WHY CHOOSE US</h2>
            </div>
          </div>
          <div className="row">
            {/*  Single Project  */}
            <div className="col-lg-4 col-md-6 col-12 mb-30">
              <div className="single-project">
                {/*  Image  */}
                <img src="assets/img/about/3.jpg" alt="" />
                {/*  Title  */}
                <h4><a href="#">Quality and Consistency</a></h4>
                {/*  Tags  */}
                {/* <div className="tags fix">
                  <a href="#">Energy</a>
                  <a href="#">Green</a>
                  <a href="#">Solar</a>
                </div> */}
                {/*  Content  */}
                <p>A Team of Experts will always make sure that the quality of the product is up to the mark and it remains same till the end.</p>
                {/*  Button  */}
                {/* <a className="button" href="#">Learn More</a> */}
              </div>
            </div>
            {/*  Single Project  */}
            <div className="col-lg-4 col-md-6 col-12 mb-30">
              <div className="single-project">
                {/*  Image  */}
                <img src="assets/img/about/2.jpg" alt="" />
                {/*  Title  */}
                <h4><a href="#">Ontime Delivery</a></h4>
                {/*  Tags  */}
                {/* <div className="tags fix">
                  <a href="#">Energy</a>
                  <a href="#">Green</a>
                  <a href="#">Solar</a>
                </div> */}
                {/*  Content  */}
                <p>Wide Distribution Network and Skilled logistic professionals ensures On-Time delivery of orders to Customers</p>
                {/*  Button  */}
                {/* <a className="button" href="#">Learn More</a> */}
              </div>
            </div>
            {/*  Single Project  */}
            <div className="col-lg-4 col-md-6 col-12 mb-30">
              <div className="single-project">
                {/*  Image  */}
                <img src="assets/img/about/1.jpg" alt="" />
                {/*  Title  */}
                <h4><a href="#">Customer Satisfaction</a></h4>
                {/*  Tags  */}
                {/* <div className="tags fix">
                  <a href="#">Energy</a>
                  <a href="#">Green</a>
                  <a href="#">Solar</a>
                </div> */}
                {/*  Content  */}
                <p>Our Company's prime motto is Customer Satisfaction. We deliver high quality and durable products to customer.</p>
                {/*  Button  */}
                {/* <a className="button" href="#">Learn More</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Project Area End */}
      {/* FunFact Area Start */}
      <div id="funfact-area" className="funfact-area section pb-100">
        <div className="container">
          {/* Section Title */}
          <div className="row">
            <div className="section-title text-center col-12 mb-80">
              <h2>OUR PRODUCTS</h2>
              {/* <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p> */}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="d-flex justify-content-center flex-wrap col-auto">
              {/*  Single Fact  */}
              <div className="single-fact">
                <img src="assets/img/service/polyethylene-granules.jpg" width="150" height="150" alt="" />
                <span className="border" />
                {/* <h1><span className="counter">Polyethylene Granules</span></h1> */}
                <a href="/polythylene-granules">Polythylene Granules</a>
                
              </div>
              {/*  Single Fact  */}
              <div className="single-fact">
                <img src="assets/img/service/polyethylene-granules.jpg" width="150" height="150" alt="" />
                <span className="border" />
                <a href="/polypropylene-granules">Polyepropylene Granules</a>
              </div>
              {/*  Single Fact  */}
              <div className="single-fact">
                <img src="assets/img/service/ppcp-granules.jpg" width="150" height="150" alt="" />
                <span className="border" />
                <a href="ppcp-granules">PPCP Granules</a>
              </div>
              {/*  Single Fact  */}
              <div className="single-fact">
                <img src="assets/img/service/abs-granules.jpg" width="150" height="150" alt="" />
                <span className="border" />
                <a href="/abs-granules">ABS Granules</a>
              </div>
              {/*  Single Fact  */}
              <div className="single-fact">
                <img src="assets/img/service/poly.jpg" width="150" height="150" alt="" />
                <span className="border" />
                <a href="/polycarbonate-granules">Polycarbonate Granules</a>
              </div>
              {/*  Single Fact  */}
              <div className="single-fact">
                <img src="assets/img/service/tpe-granules.jpg" width="150" height="150" alt="" />
                <span className="border" />
                <a href="/tpe-granules">TPE Granules</a>
              </div>
              {/*  Single Fact  */}
              <div className="single-fact">
                <img src="assets/img/service/hips-granules.jpg" width="150" height="150" alt="" />
                <span className="border" />
                <a href="/hips-granules">HIPS Granules</a>
              </div>
              {/*  Single Fact  */}
              <div className="single-fact">
                <img src="assets/img/service/hm-granules.jpg" width="150" height="150" alt="" />
                <span className="border" />
                <a href="/hm-granules">HM Granules</a>
              </div>
              {/*  Single Fact  */}
              <div className="single-fact">
                <img src="assets/img/service/acrylic.jpg" width="150" height="150" alt="" />
                <span className="border" />
                <a href="/nylon">Nylon</a>
              </div>
              {/*  Single Fact  */}
              <div className="single-fact">
                <img src="assets/img/service/polyethylene-granules (1).jpg" width="150" height="150" alt="" />
                <span className="border" />
                <a href="acrylic">Acrylic</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FunFact Area End */}

      {/* Client Area Start */}
      {/* <div id="client-area" className="client-area bg-dark section pt-115 pb-115">
        <div className="container">
          <div className="row">
            <div className="section-title text text-center text-white col-12 mb-80">
              <h2>Testimonials</h2>
            </div>
          </div>
          <div className="client-slider row">
            <div className="client-item col"><img src="assets/img/clients/1.png" alt="" /></div>
            <div className="client-item col"><img src="assets/img/clients/2.png" alt="" /></div>
            <div className="client-item col"><img src="assets/img/clients/3.png" alt="" /></div>
            <div className="client-item col"><img src="assets/img/clients/4.png" alt="" /></div>
            <div className="client-item col"><img src="assets/img/clients/5.png" alt="" /></div>
          </div>
        </div>
      </div> */}
      {/* Client Area End */}

    </React.Fragment>
  );
}

export default Main;



import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
// import AboutSection from './AboutSection';


export default function About() {
  console.log('about==============>')
  return (
    <div>
      {/* Page Banner Area Start */}
      <div className="page-banner-area section overlay gradient">
        <div className="container">
          <div className="row">
            <div className="page-banner col-12">
              <h2>ABOUT US</h2>
              <ul>
                <li><NavLink to="/">Home</NavLink></li>
                {/* <li><a href="index.html">HOME</a></li> */}
                <li> <NavLink to="/about">About</NavLink></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Page Banner Area End */}
      <div id="about-area" className="about-area section pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        {/*  About Image  */}
                        <div class="col-lg-5 col-12 order-lg-2">
                            <div class="about-image-2">
                                <img src="assets/img/about/logo.jpg" alt="" />
                                {/* <a class="video-popup play-btn" href="https://www.youtube.com/watch?v=mcixldqDIEQ"><i class="zmdi zmdi-play"></i></a> */}
                            </div>
                        </div>
                        {/*  About Content  */}
                        <div className="about-content-2 col-lg-7 col-12 pl-3">
                            <h2>Welcome to</h2>
                            <h1>CRYSTAL POLYMERS</h1>
                            <p>Crystal Polymers is one of the fastest growing and leading plastic granules manufacturer since 2017, located at Por GIDC, Vadodara. With immense information and ultra modern production technologies, we have been crossing the peaks of success. We are known for our on time delivery. Customer satisfaction is our prime motto. Our granules are widely acceptable in different industries like Automobile parts, Kitchenware, Moulding, Household items, Electronics, Pipes, Nets etc. We offer Plastic Raw Materials and Reprocessed Plastic Granules such as PP granules, PPCP granules, ABS granules, HD granules, LD granules, PC granules, HIPS granules, HM granules, Nylon granules, Acrylic granules and Recycled plastic granules.</p>
                            {/* <a href="#" className="button black">JOIN NOW</a> */}
                        </div>
                    </div>
                </div>
            </div>
      {/* Service Area Start */}
      <div id="service-area" className="service-area section pb-120">
        <div className="container">
          <div className="row">
            {/*  Single Service  */}
            <div className="single-service text-center col-lg-3 col-md-6 col-12">
              <img src="assets/img/service/1.png" alt="" />
              <h4>Recycling</h4>
              {/* <p>There are many v ofpasages of Lorem Ipsum available, but the majority have sufred</p> */}
            </div>
            {/*  Single Service  */}
            <div className="single-service text-center col-lg-3 col-md-6 col-12">
              <img src="assets/img/service/2.png" alt="" />
              <h4>Organic</h4>
              {/* <p>There are many v ofpasages of Lorem Ipsum available, but the majority have sufred</p> */}
            </div>
            {/*  Single Service  */}
            <div className="single-service text-center col-lg-3 col-md-6 col-12">
              <img src="assets/img/service/3.png" alt="" />
              <h4>Eco System</h4>
              {/* <p>There are many v ofpasages of Lorem Ipsum available, but the majority have sufred</p> */}
            </div>
            {/*  Single Service  */}
            <div className="single-service text-center col-lg-3 col-md-6 col-12">
              <img src="assets/img/service/4.png" alt="" />
              <h4>Biology</h4>
              {/* <p>There are many v ofpasages of Lorem Ipsum available, but the majority have sufred</p> */}
            </div>
          </div>
        </div>
      </div>
      {/* Service Area End */}
      {/* Volunteer Area Start */}
      {/* <div id="volunteer-area" className="volunteer-area section pt-120 pb-70">
        <div className="container">
          {/* Section Title */}
          {/* <div className="row">
            <div className="section-title white text-center col-12 mb-80">
              <h2>OUR VOLUNTEERS</h2>
            </div>
          </div> */}
          {/* <div className="row">
            {/* Single Volunteer */}
            {/* <div className="single-volunteer col-lg-3 col-md-6 col-12 mb-50">
              <div className="image"><img src="assets/img/volunteer/1.jpg" alt="" /></div>
              <div className="content fix">
                <h4>Robiul Siddikee</h4>
                <span>Senior Worker</span>
                <p>There are many variations of passages of Lorem Ipsum available, bhe majority have suffered alteration</p>
              </div>
            </div> */}
            {/* Single Volunteer */}
            {/* <div className="single-volunteer col-lg-3 col-md-6 col-12 mb-50">
              <div className="image"><img src="assets/img/volunteer/2.jpg" alt="" /></div>
              <div className="content fix">
                <h4>Jack Cox</h4>
                <span>Senior Worker</span>
                <p>There are many variations of passages of Lorem Ipsum available, bhe majority have suffered alteration</p>
              </div>
            </div>
            Single Volunteer */}
            {/* <div className="single-volunteer col-lg-3 col-md-6 col-12 mb-50">
              <div className="image"><img src="assets/img/volunteer/3.jpg" alt="" /></div>
              <div className="content fix">
                <h4>Johnny Lucas</h4>
                <span>Senior Worker</span>
                <p>There are many variations of passages of Lorem Ipsum available, bhe majority have suffered alteration</p>
              </div>
            </div> */}
            {/* Single Volunteer */}
            {/* <div className="single-volunteer col-lg-3 col-md-6 col-12 mb-50">
              <div className="image"><img src="assets/img/volunteer/4.jpg" alt="" /></div>
              <div className="content fix">
                <h4>Jack Martin</h4>
                <span>Senior Worker</span>
                <p>There are many variations of passages of Lorem Ipsum available, bhe majority have suffered alteration</p>
              </div>
            </div>
          </div>
        </div> 
      </div>  */}
      {/* Volunteer Area End */}
      {/* FunFact Area Start */}
      {/* <div id="funfact-area" className="funfact-area section pt-120 pb-100">
        <div className="container">
          {/* Section Title */}
          {/* <div className="row">
            <div className="section-title text-center col-12 mb-80">
              <h2>SOME FACTS</h2>
              <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="d-flex justify-content-center flex-wrap col-auto">
              {/*  Single Fact  */}
              {/* <div className="single-fact">
                <img src="assets/img/funfact/1.png" alt="" />
                <span className="border" />
                <h1><span className="counter">54514</span></h1>
                <p>PROJECTS</p>
              </div> */}
              {/*  Single Fact  */}
              {/* <div className="single-fact">
                <img src="assets/img/funfact/2.png" alt="" />
                <span className="border" />
                <h1>$<span className="counter">58785</span></h1>
                <p>DONATIONS</p>
              </div> */}
              {/*  Single Fact  */}
              {/* <div className="single-fact">
                <img src="assets/img/funfact/3.png" alt="" />
                <span className="border" />
                <h1>$<span className="counter">28785</span></h1>
                <p>RAISED</p>
              </div> */}
              {/*  Single Fact  */}
              {/* <div className="single-fact">
                <img src="assets/img/funfact/4.png" alt="" />
                <span className="border" />
                <h1><span className="counter">504</span></h1>
                <p>DONATORS</p>
              </div>
            </div>
          </div>
        </div>
      </div> */} 
      {/* FunFact Area End */}
      {/* Testimonial Area Start */}
      <div id="testimonial-area" className="testimonial-area section overlay pt-120 pb-100">
        <div className="container">
          <h1>Testimonials</h1>
          {/* Testimonial Slider */}
          <div className="testimonial-slider row">
            {/* Single Testimonial */}
            <div className="single-testimonial col-12">
            <div className="author fix">
                {/* <img src="assets/img/testimonial/1.jpg" alt="" /> */}
                <div className="details fix">
                  <h4>Jack Cox</h4>
                  <span>USA</span>
                </div>
              </div>
              <div className="content">
                <p>Crystal Polymers is the best. Ontime Delivery and that too with the best quality.</p>
              </div>
              
            </div>
            {/* Single Testimonial */}
            <div className="single-testimonial col-12">
            <div className="author fix">
                {/* <img src="assets/img/testimonial/2.jpg" alt="" /> */}
                <div className="details fix">
                  <h4>Jose Miller</h4>
                  <span>UK</span>
                </div>
              </div>
              <div className="content">
                <p>It was nice to work with Crystal Polymers.</p>
              </div>
              
            </div>
            {/* Single Testimonial */}
            <div className="single-testimonial col-12">
            <div className="author fix">
                {/* <img src="#" alt="" /> */}
                <div className="details fix">
                  <h4>Mr. Desai</h4>
                  <span>India</span>
                </div>
              </div>
              <div className="content">
                <p>Crystal Polymer is providing best quality for best price. I am a permanent client now </p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      {/* Testimonial Area End */}
    </div>
  )
}



// import React from 'react'

// export default function About() {
//   return (
//     <div>
//       <h1>maitri</h1>
//     </div>
//   )
// }

import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Service() {
    return (
        <div>
            {/* <!-- Page Banner Area Start --> */}
<div class="page-banner-area section overlay gradient">
    <div class="container">
        <div class="row">
            <div class="page-banner col-12">
                <h2>service details</h2>
                <ul>
                    {/* <li><a href="index.html">HOME</a></li> */}
                    <li><NavLink to="/">Home</NavLink></li>
                    {/* <li><a href="#">service details</a></li> */}
                    <li><NavLink to="/service">service</NavLink></li>
                </ul>
            </div>
        </div>
    </div>
</div>
{/* <!-- Page Banner Area End --> */}

{/* <!-- Contact Area Start --> */}
<div id="blog-area" class="blog-area section pt-120 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-xl-9 col-lg-8 col-12 mb-50">
                <div class="row">
                    {/* <!--  Blog Item  --> */}
                    <div class="col-md-6 col-12 mb-30">
                        <div class="blog-item">
                            {/* <!--  Image  --> */}
                            <a href="blog-details.html" class="image"><img src="assets/img/blog/1.jpg" alt=""/></a>
                            {/* <!--  Content  --> */}
                            <div class="content">
                                <h4><a href="blog-details.html">All the Lorem Ipsum Gener</a></h4>
                                {/* <!--  Meta  --> */}
                                <div class="meta fix">
                                    <span>28 January 2016</span>
                                    <span><a href="#">3 Comment</a></span>
                                </div>
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from</p>
                                <a href="blog-details.html" class="read-more">LEARN MORE</a>
                            </div>
                        </div>
                    </div>
                    {/* <!--  Blog Item  --> */}
                    <div class="col-md-6 col-12 mb-30">
                        <div class="blog-item">
                            {/* <!--  Image  --> */}
                            <a href="blog-details.html" class="image"><img src="assets/img/blog/2.jpg" alt=""/></a>
                            {/* <!--  Content  --> */}
                            <div class="content">
                                <h4><a href="blog-details.html">Finibus Bonorum et Malorum</a></h4>
                                {/* <!--  Meta  --> */}
                                <div class="meta fix">
                                    <span>28 January 2016</span>
                                    <span><a href="#">3 Comment</a></span>
                                </div>
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from</p>
                                <a href="blog-details.html" class="read-more">LEARN MORE</a>
                            </div>
                        </div>
                    </div>
                    {/* <!--  Blog Item  --> */}
                    <div class="col-md-6 col-12 mb-30">
                        <div class="blog-item">
                            {/* <!--  Image  --> */}
                            <a href="blog-details.html" class="image">
                                <img src="assets/img/blog/3.jpg" alt=""/>

                                </a>
                            {/* <!--  Content  --> */}
                            <div class="content">
                                <h4><a href="blog-details.html">The standard Lorem Ipsuma</a></h4>
                                {/* <!--  Meta  --> */}
                                <div class="meta fix">
                                    <span>28 January 2016</span>
                                    <span><a href="#">3 Comment</a></span>
                                </div>
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from</p>
                                <a href="blog-details.html" class="read-more">LEARN MORE</a>
                            </div>
                        </div>
                    </div>
                    {/* <!--  Blog Item  --> */}
                    <div class="col-md-6 col-12 mb-30">
                        <div class="blog-item">
                            {/* <!--  Image  --> */}
                            <a href="blog-details.html" class="image">
                                <img src="assets/img/blog/1.jpg" alt=""/>

                            </a>
                            {/* <!--  Content  --> */}
                            <div class="content">
                                <h4><a href="blog-details.html">All the Lorem Ipsum Gener</a></h4>
                                {/* <!--  Meta  --> */}
                                <div class="meta fix">
                                    <span>28 January 2016</span>
                                    <span><a href="#">3 Comment</a></span>
                                </div>
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from</p>
                                <a href="blog-details.html" class="read-more">LEARN MORE</a>
                            </div>
                        </div>
                    </div>
                    {/* <!--  Blog Item  --> */}
                    <div class="col-md-6 col-12 mb-30">
                        <div class="blog-item">
                            {/* <!--  Image  --> */}
                            <a href="blog-details.html" class="image">
                                <img src="assets/img/blog/2.jpg" alt=""/>

                                </a>
                            {/* <!--  Content  --> */}
                            <div class="content">
                                <h4><a href="blog-details.html">Finibus Bonorum et Malorum</a></h4>
                                {/* <!--  Meta  --> */}
                                <div class="meta fix">
                                    <span>28 January 2016</span>
                                    <span><a href="#">3 Comment</a></span>
                                </div>
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from</p>
                                <a href="blog-details.html" class="read-more">LEARN MORE</a>
                            </div>
                        </div>
                    </div>
                    {/* <!--  Blog Item  --> */}
                    <div class="col-md-6 col-12 mb-30">
                        <div class="blog-item">
                            {/* <!--  Image  --> */}
                            <a href="blog-details.html" class="image">
                                <img src="assets/img/blog/3.jpg" alt=""/>

                                </a>
                            {/* <!--  Content  --> */}
                            <div class="content">
                                <h4><a href="blog-details.html">The standard Lorem Ipsuma</a></h4>
                                {/* <!--  Meta  --> */}
                                <div class="meta fix">
                                    <span>28 January 2016</span>
                                    <span><a href="#">3 Comment</a></span>
                                </div>
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from</p>
                                <a href="blog-details.html" class="read-more">LEARN MORE</a>
                            </div>
                        </div>
                    </div>
                    {/* <!--  Blog Item  --> */}
                    <div class="col-md-6 col-12 mb-30">
                        <div class="blog-item">
                            {/* <!--  Image  --> */}
                            <a href="blog-details.html" class="image">
                                <img src="assets/img/blog/1.jpg" alt=""/>

                                </a>
                            {/* <!--  Content  --> */}
                            <div class="content">
                                <h4><a href="blog-details.html">All the Lorem Ipsum Gener</a></h4>
                                {/* <!--  Meta  --> */}
                                <div class="meta fix">
                                    <span>28 January 2016</span>
                                    <span><a href="#">3 Comment</a></span>
                                </div>
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from</p>
                                <a href="blog-details.html" class="read-more">LEARN MORE</a>
                            </div>
                        </div>
                    </div>
                    {/* <!--  Blog Item  --> */}
                    <div class="col-md-6 col-12 mb-30">
                        <div class="blog-item">
                            {/* <!--  Image  --> */}
                            <a href="blog-details.html" class="image">
                                <img src="assets/img/blog/2.jpg" alt=""/>

                            </a>
                            {/* <!--  Content  --> */}
                            <div class="content">
                                <h4><a href="blog-details.html">Finibus Bonorum et Malorum</a></h4>
                                {/* <!--  Meta  --> */}
                                <div class="meta fix">
                                    <span>28 January 2016</span>
                                    <span><a href="#">3 Comment</a></span>
                                </div>
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from</p>
                                <a href="blog-details.html" class="read-more">LEARN MORE</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--  Pagination --> */}
                <div class="pagination">
                    <ul>
                        <li><a href="#"><i class="zmdi zmdi-chevron-left"></i></a></li>
                        <li class="active"><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#">5</a></li>
                        <li><a href="#"><i class="zmdi zmdi-chevron-right"></i></a></li>
                    </ul>
                </div>
            </div>
            {/* <!--  Sidebar --> */}
            <div class="col-xl-3 col-lg-4 col-12 mb-50">
                {/* <!--  Single Sidebar --> */}
                <div class="single-sidebar">
                    <h3 class="title">search</h3>
                    <div class="search-sidebar">
                        <form action="#">
                            <input type="text" placeholder="Enter Key Words"/>
                            <button><i class="zmdi zmdi-search"></i></button>
                        </form>
                    </div>
                </div>
                {/* <!--  Single Sidebar --> */}
                <div class="single-sidebar">
                    <h3 class="title">About <span>Project</span></h3>
                    <div class="about-sidebar">
                        <p>Lorem ipsum dolor sit amet, conseetur adipiscing elit, sed do eiusmodtempor incididunt ut labore et dolorealiqua. Ut enim ad minim</p>
                    </div>
                </div>
                {/* <!--  Single Sidebar --> */}
                <div class="single-sidebar">
                    <h3 class="title">Recent <span>Post</span></h3>
                    <ul class="recent-post-sidebar">
                        <li>
                            <a href="#" class="image float-left">
                                <img src="assets/img/blog/sidebar/recent-1.jpg" alt=""/>

                                </a>
                            <div class="content fix">
                                <a href="#">We denounce with</a>
                                <p>eiusmodtempor incididunt ut labore et dolorea.</p>
                            </div>
                        </li>
                        <li>
                            <a href="#" class="image float-left">
                                <img src="assets/img/blog/sidebar/recent-2.jpg" alt=""/>
                                {/*  */}
                            </a>
                            <div class="content fix">
                                <a href="#">One who avoids</a>
                                <p>eiusmodtempor incididunt ut labore et dolorea.</p>
                            </div>
                        </li>
                        <li>
                            <a href="#" class="image float-left">
                                <img src="assets/img/blog/sidebar/recent-3.jpg" alt=""/>
                                {/*  */}
                            </a>
                            <div class="content fix">
                                <a href="#">Which is the same</a>
                                <p>eiusmodtempor incididunt ut labore et dolorea.</p>
                            </div>
                        </li>
                    </ul>
                </div>
                {/* <!--  Single Sidebar -->/ */}
                <div class="single-sidebar">
                    <h3 class="title">Make <span>Donation</span></h3>
                    <div class="donation-sidebar">
                        <form action="#">
                            <div class="input-box"><input type="text" placeholder="YOUR NAME"/></div>
                            <div class="input-box"><input type="email" placeholder="EMAIL"/></div>
                            <div class="input-box">
                                <select>
                                    <option value="1">SELECT PROJECT</option>
                                    <option value="2">PROJECT NUMBER 1</option>
                                    <option value="3">PROJECT NUMBER 2</option>
                                    <option value="4">PROJECT NUMBER 3</option>
                                    <option value="5">PROJECT NUMBER 4</option>
                                    <option value="6">PROJECT NUMBER 4</option>
                                </select>
                            </div>
                            <div class="input-box">
                                <select>
                                    <option value="1">SELECT AMOUNT</option>
                                    <option value="2">$1000</option>
                                    <option value="3">$1500</option>
                                    <option value="4">$2000</option>
                                    <option value="5">$2500</option>
                                    <option value="6">$3000</option>
                                </select>
                            </div>
                            <div class="input-box"><input type="submit" value="donate now"/></div>
                        </form>
                    </div>
                </div>
                {/* <!--  Single Sidebar --> */}
                <div class="single-sidebar">
                    <h3 class="title">Tags</h3>
                    <div class="tags-sidebar">
                        <a href="#">Volunteer</a>
                        <a href="#">Donate</a>
                        <a href="#">Water</a>
                        <a href="#">Money</a>
                        <a href="#">Child</a>
                        <a href="#">New</a>
                        <a href="#">Services</a>
                        <a href="#">Doller</a>
                    </div>
                </div>
                {/* <!--  Single Sidebar --> */}
                <div class="single-sidebar">
                    <h3 class="title">Categories</h3>
                    <ul class="category-sidebar">
                        <li><a href="#">Environment (2)</a></li>
                        <li><a href="#">Forest (4)</a></li>
                        <li><a href="#">Water (6)</a></li>
                        <li><a href="#">Donate (3)</a></li>
                        <li><a href="#">Volunteer (4)</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
{/* <!-- Contact Area End --> */}
        </div>
    )
}

import React from 'react';
import { NavLink } from 'react-router-dom'
import Enquiry from './sidebar/Enquiry'
import Rightsidebar from './sidebar/Rightsidebar'


function Polypropylene() {
    return (
        <React.Fragment>
            <div>
                {/* <!-- Page Banner Area Start --> */}
                <div class="page-banner-area section overlay gradient">
                    <div class="container">
                        <div class="row">
                            <div class="page-banner col-12">
                                <h2>product</h2>
                                <ul>
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><NavLink to="product">Products</NavLink></li>
                                    <li><NavLink to="/polypropylene-granules">Polypropylene</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Page Banner Area End --> */}

                {/* Description Starts */}

                <div id="project-area" class="project-area section pt-120 pb-70">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-9 col-lg-8 col-12 mb-50">
                                <div class="single-project-details">
                                    <img class="main-image" src="assets/img/products/Polyprolylene.jpg" alt="" />
                                    <h3 class="title">Polypropylene Granules</h3>
                                    {/* <div class="tags fix">
                                    <a href="#">Energy</a>
                                    <a href="#">Green</a>
                                    <a href="#">Solar</a>
                                </div> */}
                                    {/* <!--  Content  --> */}
                                    <p>We are engaged in the Manufacturing and Supplying of Polypropylene Granules. The Polypropylene Granules are hard and tough and used to make moulded particles. We offer premium Polypropylene Granules best known for their high tensile strength and elongation. We offer the Polypropylene Granules at the competitive prices to clients.</p>

                                    <h3 class="title">About Polyprolylene</h3>
                                    <p>Polypropylene (PP), a polymer prepared catalytically from propylene which differs from HDPE by having an isostatic replacement of a hydrogen atom by a methyl group on alternate carbon atoms in the main chain. Although largely unreactive chemically the presence of the methyl groups makes Polypropylene slightly more susceptible to attack by strong oxidizing agents than HDPE. A major advantage is Polypropylene's higher temperature resistance, which makes PP particularly suitable for items such as trays, funnels, pails, bottles, carboys and instrument jars that have to be sterilized frequently for use in a clinical environment. Polypropylene is a translucent material with excellent mechanical properties and it has gradually replaced the polyethylenes for many purposes.</p>


                                    <h3 class="title">Polyprolylene Resistance</h3>
                                    <ol>
                                        <li>Excellent resistance (no attack) to dilute and concentrated Acids, Alcohols, Bases and Mineral Oils</li>
                                        <li>Good resistance (minor attack) to Aldehydes, Esters, Aliphatic Hydrocarbons, Ketones and Vegetable Oils</li>
                                        <li>Limited resistance (moderate attack and suitable for short term use only) to Aromatic and Halogenated Hydrocarbons and Oxidizing Agents</li>
                                        <li>Reduces static charge build up in products</li>
                                        <li>Resistant to chemical solvents and acids</li>
                                        <li>Tough and flexible</li>
                                        <li>Contains Slip and Anti-block Additives</li>
                                        <li>Good Process ability on water bath</li>
                                        <li>Excellent mechanical properties</li>
                                    </ol>

                                    <h3 class="title">Polyprolylene Fabrication</h3>
                                    <ol>
                                        <li>Great chemical resistance makes Polypropylene a popular choice for plating and chemical tanks, as well as laboratory cabinetry and semi-conductor bench tops</li>
                                        <li>This material machines well and is available in many profiles</li>
                                        <li>Propylene is autoclaveable for usage in biohazardous environments</li>
                                        <li>Other uses include fittings and connectors, storage containers, sinks, and hoods</li>
                                        <li>The best joining method is hot air or nitrogen welding. Can be mechanically joined with screws or rivets. Ultrasonic inserts work well</li>
                                    </ol>

                                    <h3 class="title">Applications</h3>
                                    <ol>
                                        <li>Textile Over wraps</li>
                                        <li>Snack Food Packaging</li>
                                        <li>Packaging Industry</li>
                                        <li>Stationery Industry</li>
                                        <li>Garment paper Bags & Woven Fabrics</li>
                                        <li>Furniture and House ware Industry</li>
                                        <li>Textile Industry</li>
                                        <li>Plastic parts and reusable containers of various types</li>
                                    </ol>

                                    <h3 class="title">Quick Facts</h3>
                                    <ol>
                                        <li>Maximum Temperature : 275°F 135°C</li>
                                        <li>Autoclavable : Yes</li>
                                        <li>Tensile Strength : 4500 psi</li>
                                        <li>UV Resistance : Poor</li>
                                        <li>Rigid</li>
                                        <li>Minimum Temperature : 32°F 0°C</li>
                                        <li>Melting Point : 338°F 170°C</li>
                                        <li>Hardness : R95</li>
                                        <li>Translucent</li>
                                        <li>Specific Gravity : 0.90</li>
                                    </ol>

                                </div>

                                {/* PRODUCT TYPE AREA */}
                                
                                <div id="project-area" class="project-area section pt-120 pb-90">
                                <h3 class="title">Types of Polyprolylene Granules</h3>

                                    <div class="container">
                                        <div class="row">
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/ppmilky.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">PP Milky</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/* <div class="tags fix"> 
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                    </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/ppnatural.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">PP Natural</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/*<div class="tags fix">
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                    </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/ppplainblack.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">PP Plain Black</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/*<div class="tags fix">
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            
                                            
                                           
                                           
                                            
                                        </div>
                                    </div>
                                </div>

                                <Enquiry />
                            </div>
                            <Rightsidebar />
                        </div>
                    </div>
                </div>

                
            </div>
        </React.Fragment>
    );
}

export default Polypropylene;

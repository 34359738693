import React from 'react'
import { NavLink } from 'react-router-dom'
import Enquiry from './sidebar/Enquiry'
import Rightsidebar from './sidebar/Rightsidebar'

export default function Ldpe() {
    return (
        <div>

            {/* <!-- Page Banner Area Start --> */}
            <div class="page-banner-area section overlay gradient">
                <div class="container">
                    <div class="row">
                        <div class="page-banner col-12">
                            <h2>product</h2>
                            <ul>
                                <li><NavLink to="/">Home</NavLink></li>
                                <li><NavLink to="product">Products</NavLink></li>
                                <li><NavLink to="/polythylene-granules">Polythylene Granules</NavLink></li>
                                <li><NavLink to="/ldpe-granules">LDPE Granules</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Page Banner Area End --> */}

            {/* <!-- Contact Area Start --> */}
            <div id="project-area" class="project-area section pt-120 pb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-9 col-lg-8 col-12 mb-50">
                            <div class="single-project-details">
                                <img class="main-image" src="assets/img/products/ldpe-granule.jpg" alt="" />
                                <h3 class="title">LDPE Granules</h3>
                               
                                <p>We are engaged in offering very soft and pliable LDPE Granules for various applications. Also called thermoplastic, LDPE is a substantially linear polymer (polyethylene) made from petroleum. Moreover, our company supplies optimum quality Low Density Polyethylene Granules at market leading rates.</p>
                                
                                <h3 class="title">About LDPE</h3>
                                <ol>
                                    <li>LDPE Granules Low-density polyethylene (LDPE) is an easily recycled, versatile thermoplastic</li>
                                    <li>LDPE is formed into thin films for use as grocery bags, food wrapping material, power cable sheathing and worktable coating</li>
                                    <li>Laboratory containers used in chemical research are often made from primarily LDPE</li>
                                </ol>

                                <h3 class="title">LDPE Resistance</h3>
                                <ol>
                                    <li>Excellent resistance (no attack) to dilute and concentrated Acids, Alcohols and Bases</li>
                                    <li>Good resistance (minor attack) to Aldehydes, Esters, Aliphatic and Aromatic Hydrocarbons, Ketones and Mineral and Vegetable Oils</li>
                                    <li>Limited resistance (moderate attack and suitable for short term use only) to Halogenated Hydrocarbons and Oxidizing Agents</li>
                                    <li>Poor resistance and not recommended for use with Halogenated Hydrocarbons</li>
                                </ol>

                                <h3 class="title">LDPE Fabrication</h3>
                                <ol>
                                    <li>Films & Liners</li>
                                    <li>Tarpaulins</li>
                                    <li>Trays & containers</li>
                                    <li>Corrosion resistant work surfaces</li>
                                    <li>Carry Bags</li>
                                    <li>Good for parts that require flexibility</li>
                                    <li>CFood storage and laboratory</li>
                                </ol>

                                <h3 class="title">Quick Facts</h3>
                                <ol>
                                    <li>Maximum Temperature : 176°F 80°C</li>
                                    <li>Autoclavable : No</li>
                                    <li>Tensile Strength : 1700 psi</li>
                                    <li>UV Resistance : Poor</li>
                                    <li>Excellent flexibility</li>
                                    <li>Minimum Temperature : -58°F - -50°C</li>
                                    <li>Melting Point : 248°F 120°C</li>
                                    <li>Hardness : SD55</li>
                                    <li>Translucent</li>
                                    <li>Specific Gravity : 0.92</li>
                                </ol> 
                            </div>
                            <Enquiry />
                        </div>
                        <Rightsidebar />
                    </div>
                </div>
            </div>
            {/* <!-- Contact Area End --> */}
        </div>

    )
}

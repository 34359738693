import React from "react";
import { NavLink } from "react-router-dom";

function Rightsidebar() {
    return (
        <React.Fragment>
            {/* <!--  Sidebar --> */}
            <div class="col-xl-3 col-lg-4 col-12 mb-50">
                {/* <div class="single-sidebar">
                    <h3 class="title">search</h3>
                    <div class="search-sidebar">
                        <form action="#">
                            <input type="text" placeholder="Enter Key Words" />
                            <button><i class="zmdi zmdi-search"></i></button>
                        </form>
                    </div>
                </div> */}
                {/* <div class="single-sidebar">
                    <h3 class="title">About <span>Project</span></h3>
                    <div class="about-sidebar">
                        <p>Lorem ipsum dolor sit amet, conseetur adipiscing elit, sed do eiusmodtempor incididunt ut labore et dolorealiqua. Ut enim ad minim</p>
                    </div>
                </div> */}
                {/* <div class="single-sidebar">
                    <h3 class="title">Recent <span>Post</span></h3>
                    <ul class="recent-post-sidebar">
                        <li>
                            <NavLink to="/" class="image float-left"><img src="assets/img/blog/sidebar/recent-1.jpg" alt="" /></NavLink>
                            <div class="content fix">
                                <NavLink to="/">We denounce with</NavLink>
                                <p>eiusmodtempor incididunt ut labore et dolorea.</p>
                            </div>
                        </li>
                        <li>
                            <NavLink to="/" class="image float-left"><img src="assets/img/blog/sidebar/recent-2.jpg" alt="" /></NavLink>
                            <div class="content fix">
                                <NavLink to="/">One who avoids</NavLink>
                                <p>eiusmodtempor incididunt ut labore et dolorea.</p>
                            </div>
                        </li>
                        <li>
                            <NavLink to="/" class="image float-left"><img src="assets/img/blog/sidebar/recent-3.jpg" alt="" /></NavLink>
                            <div class="content fix">
                                <NavLink to="/">Which is the same</NavLink>
                                <p>eiusmodtempor incididunt ut labore et dolorea.</p>
                            </div>
                        </li>
                    </ul>
                </div> */}
                {/* <div class="single-sidebar">
                    <h3 class="title">Make <span>Donation</span></h3>
                    <div class="donation-sidebar">
                        <form action="#">
                            <div class="input-box"><input type="text" placeholder="YOUR NAME" /></div>
                            <div class="input-box"><input type="email" placeholder="EMAIL" /></div>
                            <div class="input-box">
                                <select>
                                    <option value="1">SELECT PROJECT</option>
                                    <option value="2">PROJECT NUMBER 1</option>
                                    <option value="3">PROJECT NUMBER 2</option>
                                    <option value="4">PROJECT NUMBER 3</option>
                                    <option value="5">PROJECT NUMBER 4</option>
                                    <option value="6">PROJECT NUMBER 4</option>
                                </select>
                            </div>
                            <div class="input-box">
                                <select>
                                    <option value="1">SELECT AMOUNT</option>
                                    <option value="2">$1000</option>
                                    <option value="3">$1500</option>
                                    <option value="4">$2000</option>
                                    <option value="5">$2500</option>
                                    <option value="6">$3000</option>
                                </select>
                            </div>
                            <div class="input-box"><input type="submit" value="donate now" /></div>
                        </form>
                    </div>
                </div> */}
                {/* <div class="single-sidebar">
                    <h3 class="title">Tags</h3>
                    <div class="tags-sidebar">
                        <NavLink to="/">Volunteer</NavLink>
                        <NavLink to="/">Donate</NavLink>
                        <NavLink to="/">Water</NavLink>
                        <NavLink to="/">Money</NavLink>
                        <NavLink to="/">Child</NavLink>
                        <NavLink to="/">New</NavLink>
                        <NavLink to="/">Services</NavLink>
                        <NavLink to="/">Doller</NavLink>
                    </div>
                </div> */}
                <div class="single-sidebar">
                    <h3 class="title">Our Products</h3>
                    <ul class="category-sidebar">
                        <li><NavLink to="/polythylene-granules">Polythylene Granules</NavLink></li>
                        <li><NavLink to="/polypropylene-granules">Polypropylene Granules</NavLink></li>
                        <li><NavLink to="/ppcp-granules">PPCP Granules</NavLink></li>
                        <li><NavLink to="/abs-granules">ABS Granules</NavLink></li>
                        <li><NavLink to="/polycarbonate-granules">Polycarbonate Granules</NavLink></li>
                        <li><NavLink to="/tpe-granules">TPE Granules</NavLink></li>
                        <li><NavLink to="/hips-granules">HIPS Granules</NavLink></li>
                        <li><NavLink to="/hm-granules">HM Granules</NavLink></li>
                        <li><NavLink to="/nylon">Nylon</NavLink></li>
                        <li><NavLink to="/acrylic">Acrylic</NavLink></li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Rightsidebar;
import React from 'react'
import { NavLink } from 'react-router-dom'
import Enquiry from './sidebar/Enquiry'
import Rightsidebar from './sidebar/Rightsidebar'

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";

// import { Document, Page} from "react-pdf/dist/esm/entry.webpack"
// import fb1350 from 'E:\PROJECT\CRYSTAL123\crystal\public\assets\pdf\FB1350.pdf'

export default function Hdpe() {
    return (
        <React.Fragment>

            {/* <!-- Page Banner Area Start --> */}
            <div class="page-banner-area section overlay gradient">
                <div class="container">
                    <div class="row">
                        <div class="page-banner col-12">
                            <h2>product</h2>
                            <ul>
                                <li><NavLink to="/">Home</NavLink></li>
                                <li><NavLink to="product">Products</NavLink></li>
                                <li><NavLink to="/polythylene-granules">Polythylene Granules</NavLink></li>
                                <li><NavLink to="/hdpe-granules">HDPE Granules</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Page Banner Area End --> */}

            {/* <!-- Contact Area Start --> */}
            <div id="project-area" class="project-area section pt-120 pb-70">
                <div class="container">
                    <div class="row">
                        <div>
                            <div class="single-project-details">
                                {/* <img class="main-image" src="assets/img/products/hdpe.jpg" alt="" />
                                 */}
                                {/* <div class="tags fix">
                                    <a href="#">Energy</a>
                                    <a href="#">Green</a>
                                    <a href="#">Solar</a>
                                </div> */}
                                {/* <!--  Content  --> */}
                                {/* <p>We offer high quality HDPE Granules that is a multi-purpose polyethylene used in diverse industrial sectors for specific applications. These HDPE Granules are sourced from reliable manufacturers and is highly appreciated by clients for superior quality. We provide the HDPE Granules at nominal rates to clients.</p>
                                <p>Recycled HD Granules offered by us, are known for their strength as made from quality plastic scrap. Our range Recycled HD Granules finds application in various industries. Moreover, we offer Recycled HD Granules that are available in various specifications.</p>
                                
                                <h3 class="title">About HDPE</h3>
                                <p>A linear polymer, High Density Polyethylene (HDPE) is prepared from ethylene by a catalytic process. The absence of branching results in a more closely packed structure with a higher density and somewhat higher chemical resistance than LDPE. HDPE is also somewhat harder and more opaque and it can withstand rather higher temperatures (120° Celsius for short periods, 110° Celsius continuously). High density polyethylene lends itself particularly well to blow molding, e.g. for bottles, cutting boards, dipping baskets, dippers, trays and containers.</p>
                                

                                <h3 class="title">HDPE Resistance</h3>
                                <ol>
                                    <li>Excellent resistance (no attack) to dilute and concentrated Acids, Alcohols and Bases</li>
                                    <li>Good resistance (minor attack) to Aldehydes, Esters, Aliphatic and Aromatic Hydrocarbons, Ketones and Mineral and Vegetable Oils</li>
                                    <li>Limited resistance (moderate attack and suitable for short term use only) to Halogenated Hydrocarbons and Oxidizing Agents</li>
                                </ol>

                                <h3 class="title">HDPE Fabrication</h3>
                                <ol>
                                    <li>Excellent for any food related products, FDA, NSF, and USDA approved for direct contact. This material machines extremely well</li>
                                    <li>Good chemical resistance and high rigidity make it a good choice for trays and tanks</li>
                                    <li>Other uses include pipe fittings, wear plates, hinges and cutting boards</li>
                                    <li>Good impact resistance, light weight, very low moisture absorption, and high tensile strength. Not a good candidate for gluing. Mechanical fastening is one option, but preferably joined by hot air or nitrogen welding. Also, Ultrasonic, Laser, and infrared welding</li>
                                </ol>

                                <h3 class="title">Quick Facts</h3>
                                <ol>
                                    <li>Maximum Temperature : 248°F 120°C</li>
                                    <li>Autoclavable : No</li>
                                    <li>Tensile Strength : 4550 psi</li>
                                    <li>UV Resistance : Poor</li>
                                    <li>Minimum Temperature : 148°F -100°C</li>
                                    <li>Melting Point : 266°F 130°C</li>
                                    <li>Hardness : SD65</li>
                                    <li>Translucent</li>
                                    <li>Specific Gravity : 0.95</li>
                                </ol> */}

                                <h3 class="title" style={{ textAlign: 'center', paddingBottom: '20px' }}>HDPE Granules</h3>

                                <TableContainer>
                                    <Table >
                                        <TableHead style={{ backgroundColor: '#6ab43e' }}>
                                            <TableRow>
                                                <TableCell style={{ textAlign: 'center' }}>
                                                    Grade
                                                </TableCell >

                                                <TableCell style={{ textAlign: 'center' }}>
                                                    Category
                                                </TableCell>

                                                <TableCell style={{ textAlign: 'center' }}>
                                                    MFR
                                                </TableCell>

                                                <TableCell style={{ textAlign: 'center' }}>
                                                    Density
                                                </TableCell>

                                                <TableCell style={{ textAlign: 'center' }}>
                                                    Application
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>
                                                    DATASHEET
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    BB2588
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    0.23
                                                </TableCell>
                                                <TableCell>
                                                    958
                                                </TableCell>
                                                <TableCell>
                                                    Mono layer & co-extrusion films, Exclusive Carrier/ Boutique bags, Lamination (Inc. stand up Pouches), Shrink Film, Industrial Film, Frozen Food, Heavy duty shipping packs, FFS packaging Film
                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/BB2588.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    FB1460
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    Less than 0.1
                                                </TableCell>
                                                <TableCell>
                                                    946
                                                </TableCell>
                                                <TableCell>
                                                    Mono layer & co-extrusion films, Exclusive Carrier/ Boutique bags, Thin protection film, Thin bags Food Packaging, Industrial Liners, Heavy duty shipping sacks
                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/FB1460.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    FB1520
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    Less than 0.1
                                                </TableCell>
                                                <TableCell>
                                                    952
                                                </TableCell>
                                                <TableCell>
                                                    Mono layer & co-extrusion films, Exclusive Carrier/ Boutique bags, Thin protection film, Thin bags Food Packaging, Industrial Liners, Heavy duty shipping sacks
                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/FB1520.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    FB5600
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    0.7
                                                </TableCell>
                                                <TableCell>
                                                    960
                                                </TableCell>
                                                <TableCell>
                                                    Lamination films, Food Packaging films, Lamitubes, Heavy Duty Shipping
                                                    Sacks and Shrink film and Standup Pouches
                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/FB5600.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    HE1490
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    0.35
                                                </TableCell>
                                                <TableCell>
                                                    948
                                                </TableCell>
                                                <TableCell>
                                                    Range of wall thickness drip irrigation tubing for online drip laterals and flat as well as cylindrical emitting pipe/inline production, Sprinkler Irrigation Pipes, Non Pressure Utility Pipes, Thin Walled Corrugated Pipes

                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/HE1490.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    HE3450
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    0.5
                                                </TableCell>
                                                <TableCell>
                                                    952
                                                </TableCell>
                                                <TableCell>
                                                    top-coat in 3 layer PE coating of steel pipes and is suitable for severe
                                                    laying conditions even at elevated ambient temperatures. With this material, high running speeds and relatively
                                                    thin layers are obtained without problems, can be used from -40 to +80°C design
                                                    temperature of the pipeline when combined with the grafted adhesives ME0410, ME0420 or ME0433.
                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/HE1490.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    HE3460
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    0.5
                                                </TableCell>
                                                <TableCell>
                                                    960
                                                </TableCell>
                                                <TableCell>
                                                    HE3460 is suitable material for non-pressure pipe applications such as gravity sewage, storm water pipes and conduits
                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/HE3460.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    HE3490LS
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    0.25
                                                </TableCell>
                                                <TableCell>
                                                    960
                                                </TableCell>
                                                <TableCell>
                                                    pressure pipe systems used in drinking water and natural gas, pressure sewerage, relining, sea outfall and industrial applications. It is especially designed for the production of larger diameter, thick wall pipe, but can be processed for the whole range of diameters. It also shows excellent resistance to rapid crack propagation and slow crack growth                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/HE3490LS.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    HE3490LS-H
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    0.25
                                                </TableCell>
                                                <TableCell>
                                                    960
                                                </TableCell>
                                                <TableCell>
                                                    pressure pipe systems used in drinking water and natural gas, pressure sewerage, relining, sea outfall and industrial applications, especially where they are to be installed in challenging conditions. It is especially designed for the production of larger diameter, thick wall pipe, but can be processed for the whole range of diameters                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/HE3490-LS-H.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    MB6561
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    1.5
                                                </TableCell>
                                                <TableCell>
                                                    955
                                                </TableCell>
                                                <TableCell>
                                                    Caps and closures for taste-sensitive beverages like water. Consumer and industrial articles with high ESCR demands.                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/MB6561.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    MB6562
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    1.5
                                                </TableCell>
                                                <TableCell>
                                                    955
                                                </TableCell>
                                                <TableCell>
                                                    Caps and closures for taste-sensitive beverages like water. Consumer and industrial articles with high ESCR demands.                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/MB6562.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    ME3440
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    0.85
                                                </TableCell>
                                                <TableCell>
                                                    954
                                                </TableCell>
                                                <TableCell>
                                                    pressure pipe systems in the applications field of drinking water, natural gas, pressure sewerage, relining, sea outfall and industrial, where flexibility and coilability is of importance. It also shows excellent resistance to rapid crack propagation and slow crack growth. Attributed to the bimodal molecular weight distribution structure, it gives outstanding extrudability, compared to conventional PE80 products.
                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/ME3440.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    ME3441
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    0.8
                                                </TableCell>
                                                <TableCell>
                                                    944
                                                </TableCell>
                                                <TableCell>
                                                    gas pressure pipe systems where flexibility and coilability is of importance. It also shows excellent resistance to rapid crack propagation and slow crack growth. Attributed to the molecular structure, it gives outstanding extrudability, compared to conventional PE80 products.
                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/ME3441.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    FK2715
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    1.3
                                                </TableCell>
                                                <TableCell>
                                                    927
                                                </TableCell>
                                                <TableCell>
                                                    Improved Stiffness Lamination Film, Collation Shrink Film, Stand up Pouch, Food Packaging, Lamitube, Heavy Duty bags, Multilayer Film
                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/FK2715.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    FM1810
                                                </TableCell>
                                                <TableCell>
                                                    HDPE
                                                </TableCell>
                                                <TableCell>
                                                    1.1
                                                </TableCell>
                                                <TableCell>
                                                    918
                                                </TableCell>
                                                <TableCell>
                                                    Lamination, Food Packaging, Liquid Packaging, Impact Modifier, Agriculture Film, Multilayer Packaging Film
                                                </TableCell>
                                                <TableCell>
                                                    <a href='assets/pdf/FM1810.pdf' target="_blank">
                                                        <img src='assets/img/pdg.png' width="50" height="40"></img>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </div>
                            <Enquiry />
                        </div>

                    </div>
                </div>
            </div>
            {/* <!-- Contact Area End --> */}
        </React.Fragment>

    )
}

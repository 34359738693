import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Gallery() {
    console.log('gallery=====================>')
    return (
        <div>
            {/* <!-- Page Banner Area Start --> */}
            <div class="page-banner-area section overlay gradient">
                <div class="container">
                    <div class="row">
                        <div class="page-banner col-12">
                            <h2>gallery</h2>
                            <ul>
                            <li><NavLink to="/">Home</NavLink></li>
                            <li> <NavLink to="/gallery">Gallery</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Page Banner Area End --> */}

            {/* <!-- Gallery Area Start --> */}
            <div id="gallery-area" class="gallery-area section pt-120 pb-110">
                <div class="container">
                    <div class="gallery-wrapper row row-5">
                        {/* <!--  Gallery Item  --> */}
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-10">
                            <a href="assets/img/gallery/11.jpg" class="gallery-item gallery-popup">
                                <img src="assets/img/gallery/21.jpg" alt=""/>

                                </a>
                        </div>
                        {/* <!--  Gallery Item  --> */}
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-10">
                            <a href="assets/img/gallery/12.jpg" class="gallery-item gallery-popup">
                                <img src="assets/img/gallery/22.jpg" alt=""/>
                                {/*  */}
                            </a>
                        </div>
                        {/* <!--  Gallery Item  --> */}
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-10">
                            <a href="assets/img/gallery/13.jpg" class="gallery-item gallery-popup">
                                <img src="assets/img/gallery/23.jpg" alt=""/>
                                {/*  */}
                            </a>
                        </div>
                        {/* <!--  Gallery Item  --> */}
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-10">
                            <a href="assets/img/gallery/14.jpg" class="gallery-item gallery-popup">
                                <img src="assets/img/gallery/24.jpg" alt=""/>

                            </a>
                        </div>
                        {/* <!--  Gallery Item  --> */}
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-10">
                            <a href="assets/img/gallery/15.jpg" class="gallery-item gallery-popup">
                                <img src="assets/img/gallery/25.jpg" alt=""/>
                                {/*  */}
                            </a>
                        </div>
                        {/* <!--  Gallery Item  --> */}
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-10">
                            <a href="assets/img/gallery/16.jpg" class="gallery-item gallery-popup">
                                <img src="assets/img/gallery/26.jpg" alt=""/>
                                {/*  */}
                            </a>
                        </div>
                        {/* <!--  Gallery Item  --> */}
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-10">
                            <a href="assets/img/gallery/17.jpg" class="gallery-item gallery-popup">
                                <img src="assets/img/gallery/27.jpg" alt=""/>

                                </a>
                        </div>
                        {/* <!--  Gallery Item  --> */}
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-10">
                            <a href="assets/img/gallery/18.jpg" class="gallery-item gallery-popup">
                                <img src="assets/img/gallery/28.jpg" alt=""/>

                                </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Gallery Area End --> */}
        </div>
    )
}

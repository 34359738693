import React from "react";
import { NavLink } from "react-router-dom";

function Enquiry() {
    return (
        <React.Fragment>
            <div class="comment-wrapper fix">
                <h3>Enquiry Now</h3>
                <div class="comment-form">
                    <form action="#">
                        <div class="input-box input-2"><input type="text" placeholder="Name" /></div>
                        <div class="input-box input-2"><input type="email" placeholder="Email" /></div>
                        <div class="input-box"><textarea placeholder="Message"></textarea></div>
                        <div class="input-box"><input type="submit" value="Submit" /></div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Enquiry;
import React from 'react';
import { NavLink } from 'react-router-dom'
import Enquiry from './sidebar/Enquiry'
import Rightsidebar from './sidebar/Rightsidebar'


function Acrylic() {
    return (
        <React.Fragment>
            <div>
                {/* <!-- Page Banner Area Start --> */}
                <div class="page-banner-area section overlay gradient">
                    <div class="container">
                        <div class="row">
                            <div class="page-banner col-12">
                                <h2>product</h2>
                                <ul>
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><NavLink to="product">Products</NavLink></li>
                                    <li><NavLink to="/acrylic">Acrylic Granules</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Page Banner Area End --> */}

                {/* Description Starts */}

                <div id="project-area" class="project-area section pt-120 pb-70">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-9 col-lg-8 col-12 mb-50">
                                <div class="single-project-details">
                                    <img class="main-image" src="assets/img/products/acrylic-granules.jpg" alt="" />
                                    <h3 class="title">Acrylic Granules</h3>
                                    {/* <div class="tags fix">
                                    <a href="#">Energy</a>
                                    <a href="#">Green</a>
                                    <a href="#">Solar</a>
                                </div> */}
                                    {/* <!--  Content  --> */}
                                    <p>Acrylate polymers belong to a group of polymers which could be referred to generally as plastics. They are noted for their transparency, resistance to breakage, and elasticity. They are also commonly known as acrylics or polyacrylates. Acrylate polymer is commonly used in cosmetics such as nail polish as an adhesive.</p>

                                    <h3 class="title">Features</h3>
                                    <ol>
                                        <li>Good Weatherability</li>
                                        <li>Heat Resistance</li>
                                        <li>Oil Resistance</li>
                                        <li>Ozone Resistance</li>
                                        
                                    </ol>



                                    <h3 class="title">Applications</h3>
                                    <ol>
                                        <li>Adhesives</li>
                                        <li>Coating</li>
                                        <li>Plasticizers</li>
                                        <li>Textiles</li>
                                        <li>Leather</li>
                                        <li>Water Treatment</li>
                                        <li>Plastics</li>
                                    </ol>



                                </div>

                                {/* Types of Nylon */}
                                <div id="project-area" class="project-area section pt-120 pb-90">
                                <h3 class="title">Types of Acrylic Granules</h3>

                                    <div class="container">
                                        <div class="row">
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/acrylic-red.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">Acrylic Red Granules</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/* <div class="tags fix"> 
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                    </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/acrylic-granules1.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">Acrylic Umber Granules</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/*<div class="tags fix">
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                    </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/acrylic-natural-granules.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">Acrylic Natural Granules</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/*<div class="tags fix">
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            
                                            
                                            
                                           
                                           
                                            
                                        </div>
                                    </div>
                                </div>



                                <Enquiry />
                            </div>
                            <Rightsidebar />
                        </div>
                    </div>
                </div>


            </div>
        </React.Fragment>
    );
}

export default Acrylic;

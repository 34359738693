import React from 'react'
import { NavLink } from 'react-router-dom'


export default function Contact() {
    return (
        <div>
            {/* <!-- Page Banner Area Start --> */}
            <div class="page-banner-area section overlay gradient">
                <div class="container">
                    <div class="row">
                        <div class="page-banner col-12">
                            <h2>CONTACT US</h2>
                            <ul>
                            <li><NavLink to="/">Home</NavLink></li>
                            <li> <NavLink to="/contact">Contact</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Page Banner Area End --> */}

            {/* <!-- Contact Area Start --> */}
            <div id="contact-area" class="contact-area section pt-120 pb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 col-12 mx-auto">
                            {/* <!-- Contact Info --> */}
                            <div class="row mb-50">
                                <div class="col-md-4 col-12 mb-30">
                                    <div class="single-contact-info text-center" >
                                        <h4>Email</h4>
                                        <p>crystalpoly2017@gmail.com</p>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12 mb-30">
                                    <div class="single-contact-info text-center">
                                        <h4>Phone</h4>
                                        <p>+91 800 09 06677</p>
                                        <p>+91 991 35 06569</p>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12 mb-30">
                                    <div class="single-contact-info text-center">
                                        <h4>Address</h4>
                                        <p>Survey no. 873/B/3, ANSONS Limbani Estate, Road No.1, Near GETCO 66 K.V Substation, Por, Vadodara, Gujarat, 391243</p>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                {/* <!-- Contact Form --> */}
                                <div class="contact-form col-lg-7 col-12 mb-50">
                                    <h3 class="block-title">Contact Form</h3>
                                    <form id="contact-form" action="https://template.hasthemes.com/greensoul/greensoul/mail.php" method="post">
                                        <div class="input-box input-2"><input type="text" name="name" placeholder="Your name" /></div>
                                        <div class="input-box input-2"><input type="email" name="email" placeholder="Your email" /></div>
                                        <div class="input-box"><textarea name="message" placeholder="Your massage"></textarea></div>
                                        <div class="input-box"><input type="submit" value="submit" /></div>
                                    </form>
                                    <p class="form-messege"></p>
                                </div>
                                {/* <!-- Contact Map --> */}
                                <div class="contact-map col-lg-5 col-12 mb-50">
                                    <div id="contact-map"></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Contact Area End --> */}
        </div>
    )
}

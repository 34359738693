import React from 'react';
import { NavLink }from 'react-router-dom'

function AboutSection() {
    return (
        <React.Fragment>
            {/* About Area Start */}
            <div id="about-area" className="about-area section pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        {/*  About Image  */}
                        <div class="col-lg-5 col-12 order-lg-2">
                            <div class="about-image-2">
                                <img src="assets/img/about/logo.jpg" alt="" />
                                {/* <a class="video-popup play-btn" href="https://www.youtube.com/watch?v=mcixldqDIEQ"><i class="zmdi zmdi-play"></i></a> */}
                            </div>
                        </div>
                        {/*  About Content  */}
                        <div className="about-content-2 col-lg-7 col-12 pl-3">
                            <h2>Welcome to</h2>
                            <h1>CRYSTAL POLYMERS</h1>
                            <p>Crystal Polymers is one of the fastest growing and leading plastic granules manufacturer since 2017, located at Por GIDC, Vadodara. With immense information and ultra modern production technologies, we have been crossing the peaks of success. We are known for our on time delivery. Customer satisfaction is our prime motto. Our granules are widely acceptable in different industries like Automobile parts, Kitchenware, Moulding, Household items, Electronics, Pipes, Nets etc. We offer Plastic Raw Materials and Reprocessed Plastic Granules such as PP granules, PPCP granules, ABS granules, HD granules, LD granules, PC granules, HIPS granules, HM granules, Nylon granules, Acrylic granules and Recycled plastic granules.</p>
                            {/* <a href="#" className="button black">JOIN NOW</a> */}
                            <a href="/about" className="button">view details</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* About Area End */}
        </React.Fragment>
    );
}

export default AboutSection;

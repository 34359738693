import React from 'react'
import { NavLink } from 'react-router-dom'
export default function ServiceDetails() {
    return (
        <React.Fragment>

            {/* <!-- Page Banner Area Start --> */}
            <div class="page-banner-area section overlay gradient">
                <div class="container">
                    <div class="row">
                        <div class="page-banner col-12">
                            <h2>service details</h2>
                            <ul>
                            <li><NavLink to="/">Home</NavLink></li>
                            <li><NavLink to="/serviceDetails">ServiceDetails</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Page Banner Area End --> */}

            {/* <!-- Contact Area Start --> */}
            <div id="blog-area" class="blog-area section pt-120 pb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-9 col-lg-8 col-12 mb-50">
                            {/* <!-- Single Blog Details --> */}
                            <div class="single-blog-details">
                                {/* <!--  Image  --> */}
                                <img class="main-image" src="assets/img/project/single.jpg" alt="" />
                                {/* <!--  Title  --> */}
                                <h3 class="title">Unhealthy Remove Growth from Environment</h3>
                                {/* <!--  Tags  --> */}
                                <div class="meta fix">
                                    <a href="#"><i class="zmdi zmdi-calendar-check"></i> 25 Jun 2050</a>
                                    <a href="#"><i class="zmdi zmdi-folder"></i> Web Design</a>
                                    <a href="#"><i class="zmdi zmdi-comments"></i> 19</a>
                                </div>
                                {/* <!--  Content  --> */}
                                <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will ge you a complete account of the system, and expound the actual teachings of the great explorer of the truth, theaster-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor agains there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, </p>
                                <p>but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?"</p>
                                <img src="assets/img/project/small-1.jpg" alt="" />
                                <ol>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                    <li>Nam suscipit nisi non libero eleifend placerat.</li>
                                    <li>Curabitur auctor metus vel congue euismod</li>
                                    <li>Nullam quis est sit amet velit tincidunt hendrerit in quis odio.</li>
                                    <li>In id sapien viverra, mollis augue sit amet, mollis neque.</li>
                                </ol>
                                <p>and expound the actual teachings of the great explorer of the truth, theaster-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. </p>
                                {/* <!-- Blog Details Footer --> */}
                                <div class="blog-details-footer fix">
                                    <div class="social float-left">
                                        <h5>Share:</h5>
                                        <a href="#"><i class="fa fa-facebook"></i></a>
                                        <a href="#"><i class="fa fa-rss"></i></a>
                                        <a href="#"><i class="fa fa-google-plus"></i></a>
                                        <a href="#"><i class="fa fa-twitter"></i></a>
                                        <a href="#"><i class="fa fa-instagram"></i></a>
                                    </div>
                                    <div class="tag float-right">
                                        <h5>tags:</h5>
                                        <a href="#">water</a>
                                        <a href="#">donate</a>
                                        <a href="#">new</a>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Comment Wrapper --> */}
                            <div class="comment-wrapper fix">
                                {/* <!-- Comment Form --> */}
                                <h3>Leave A Comment</h3>
                                <div class="comment-form">
                                    <form action="#">
                                        <div class="input-box input-2"><input type="text" placeholder="Name" /></div>
                                        <div class="input-box input-2"><input type="email" placeholder="Email" /></div>
                                        <div class="input-box"><textarea placeholder="Message"></textarea></div>
                                        <div class="input-box"><input type="submit" value="Submit" /></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* <!--  Sidebar --> */}
                        <div class="col-xl-3 col-lg-4 col-12 mb-50">
                            {/* <!--  Single Sidebar --> */}
                            <div class="single-sidebar">
                                <h3 class="title">search</h3>
                                <div class="search-sidebar">
                                    <form action="#">
                                        <input type="text" placeholder="Enter Key Words" />
                                        <button><i class="zmdi zmdi-search"></i></button>
                                    </form>
                                </div>
                            </div>
                            {/* <!--  Single Sidebar --> */}
                            <div class="single-sidebar">
                                <h3 class="title">About <span>Project</span></h3>
                                <div class="about-sidebar">
                                    <p>Lorem ipsum dolor sit amet, conseetur adipiscing elit, sed do eiusmodtempor incididunt ut labore et dolorealiqua. Ut enim ad minim</p>
                                </div>
                            </div>
                            {/* <!--  Single Sidebar --> */}
                            <div class="single-sidebar">
                                <h3 class="title">Recent <span>Post</span></h3>
                                <ul class="recent-post-sidebar">
                                    <li>
                                        <a href="#" class="image float-left"><img src="assets/img/blog/sidebar/recent-1.jpg" alt="" /></a>
                                        <div class="content fix">
                                            <a href="#">We denounce with</a>
                                            <p>eiusmodtempor incididunt ut labore et dolorea.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <a href="#" class="image float-left"><img src="assets/img/blog/sidebar/recent-2.jpg" alt="" /></a>
                                        <div class="content fix">
                                            <a href="#">One who avoids</a>
                                            <p>eiusmodtempor incididunt ut labore et dolorea.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <a href="#" class="image float-left"><img src="assets/img/blog/sidebar/recent-3.jpg" alt="" /></a>
                                        <div class="content fix">
                                            <a href="#">Which is the same</a>
                                            <p>eiusmodtempor incididunt ut labore et dolorea.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {/* <!--  Single Sidebar --> */}
                            <div class="single-sidebar">
                                <h3 class="title">Make <span>Donation</span></h3>
                                <div class="donation-sidebar">
                                    <form action="#">
                                        <div class="input-box"><input type="text" placeholder="YOUR NAME" /></div>
                                        <div class="input-box"><input type="email" placeholder="EMAIL" /></div>
                                        <div class="input-box">
                                            <select>
                                                <option value="1">SELECT PROJECT</option>
                                                <option value="2">PROJECT NUMBER 1</option>
                                                <option value="3">PROJECT NUMBER 2</option>
                                                <option value="4">PROJECT NUMBER 3</option>
                                                <option value="5">PROJECT NUMBER 4</option>
                                                <option value="6">PROJECT NUMBER 4</option>
                                            </select>
                                        </div>
                                        <div class="input-box">
                                            <select>
                                                <option value="1">SELECT AMOUNT</option>
                                                <option value="2">$1000</option>
                                                <option value="3">$1500</option>
                                                <option value="4">$2000</option>
                                                <option value="5">$2500</option>
                                                <option value="6">$3000</option>
                                            </select>
                                        </div>
                                        <div class="input-box"><input type="submit" value="donate now" /></div>
                                    </form>
                                </div>
                            </div>
                            {/* <!--  Single Sidebar --> */}
                            <div class="single-sidebar">
                                <h3 class="title">Tags</h3>
                                <div class="tags-sidebar">
                                    <a href="#">Volunteer</a>
                                    <a href="#">Donate</a>
                                    <a href="#">Water</a>
                                    <a href="#">Money</a>
                                    <a href="#">Child</a>
                                    <a href="#">New</a>
                                    <a href="#">Services</a>
                                    <a href="#">Doller</a>
                                </div>
                            </div>
                            {/* <!--  Single Sidebar --> */}
                            <div class="single-sidebar">
                                <h3 class="title">Categories</h3>
                                <ul class="category-sidebar">
                                    <li><a href="#">Environment (2)</a></li>
                                    <li><a href="#">Forest (4)</a></li>
                                    <li><a href="#">Water (6)</a></li>
                                    <li><a href="#">Donate (3)</a></li>
                                    <li><a href="#">Volunteer (4)</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Contact Area End --> */}
        </React.Fragment>

    )
}

import React from 'react';
import { NavLink } from 'react-router-dom'
import Enquiry from './sidebar/Enquiry'
import Rightsidebar from './sidebar/Rightsidebar'


function Nylon() {
    return (
        <React.Fragment>
            <div>
                {/* <!-- Page Banner Area Start --> */}
                <div class="page-banner-area section overlay gradient">
                    <div class="container">
                        <div class="row">
                            <div class="page-banner col-12">
                                <h2>product</h2>
                                <ul>
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><NavLink to="product">Products</NavLink></li>
                                    <li><NavLink to="/nylon">Nylon Granules</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Page Banner Area End --> */}

                {/* Description Starts */}

                <div id="project-area" class="project-area section pt-120 pb-70">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-9 col-lg-8 col-12 mb-50">
                                <div class="single-project-details">
                                    <img class="main-image" src="assets/img/products/nylon-granules.jpg" alt="" />
                                    <h3 class="title">Nylon Granules</h3>
                                    {/* <div class="tags fix">
                                    <a href="#">Energy</a>
                                    <a href="#">Green</a>
                                    <a href="#">Solar</a>
                                </div> */}
                                    {/* <!--  Content  --> */}
                                    <p>These Recycled Nylon Granules are widely demanded by various industries owing to their qualitative features. Moreover, the Recycled Nylon Granules are stringently checked on various parameters before making a final dispatch to the clients.</p>

                                    <h3 class="title">Features</h3>
                                    <ol>
                                        <li>High Tensile Strength and Hardness</li>
                                        <li>Low Coefficient of Friction</li>
                                        <li>High Dimensional Stability</li>
                                        <li>High Wear and Abrasion Resistance</li>
                                        <li>High Melting Point</li>
                                        <li>Excellent Machinability</li>
                                        
                                    </ol>



                                    <h3 class="title">Applications</h3>
                                    <ol>
                                        <li>Bearings and Bushings</li>
                                        <li>Coupling and Gears</li>
                                        <li>Space rockets</li>
                                        <li>Cams and Cam Followers</li>
                                        <li>Guides and Rollers</li>
                                        <li>Washers and Wear Stripes</li>
                                    </ol>



                                </div>

                                {/* Types of Nylon */}
                                <div id="project-area" class="project-area section pt-120 pb-90">
                                <h3 class="title">Types of Nylon Granules</h3>

                                    <div class="container">
                                        <div class="row">
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/nylon-6.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">Nylon 6</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/* <div class="tags fix"> 
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                    </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/nylon-66.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">Nylon 66</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/*<div class="tags fix">
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                    </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/nylon-plain.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">Nylon Plain</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/*<div class="tags fix">
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/nylon-glass-filled.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">Nylon Glass Fileed</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/*<div class="tags fix">
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            
                                            
                                           
                                           
                                            
                                        </div>
                                    </div>
                                </div>



                                <Enquiry />
                            </div>
                            <Rightsidebar />
                        </div>
                    </div>
                </div>


            </div>
        </React.Fragment>
    );
}

export default Nylon;

import React from 'react'
import { Route,Switch,BrowserRouter } from 'react-router-dom'
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import About from './Components/Main/About';
import Gallery from './Components/Main/Gallery';
import Main from './Components/Main/Main';
import Contact from './Components/Main/Contact';
import Product from './Components/Main/Product'
import Service from './Components/Main/Service'
import ServiceDetails from './Components/Main/ServiceDetails';
import ProductDetails from './Components/Main/ProductDetails';
import Quote from './Components/Main/Quote'

import Polythylene from './Components/Main/products/Polythylene'
import Hdpe from './Components/Main/products/Hdpe'
import Ldpe from './Components/Main/products/Ldpe'
import Lldpe from './Components/Main/products/Lldpe'

import Polypropylene from './Components/Main/products/Polypropylene'

import Ppcp from './Components/Main/products/Ppcp'

import Abs from './Components/Main/products/Abs'


import Polycarbonate from './Components/Main/products/Polycarbonate'

 
import Tpe from './Components/Main/products/Tpe'
import Hips from './Components/Main/products/Hips'
import Hm from './Components/Main/products/Hm'

import Nylon from './Components/Main/products/Nylon'


import Acrylic from './Components/Main/products/Acrylic'





export default function App() {
  // let isLogged=true;
  console.log('app===>')
  return (
    <BrowserRouter>
      <Header/>
      <Switch>
        <Route exact path="/" component={Main}/>
        <Route exact path="/about" component={About}/>
        <Route exact path="/gallery" component={Gallery}/>
        <Route exact path="/Contact" component={Contact}/>
        <Route exact path="/product" component={Product}/>
        <Route exact path="/service" component={Service}/>
        <Route exact path="/serviceDetails" component={ServiceDetails}/>
        <Route exact path="/productDetails" component={ProductDetails}/>
        <Route exact path="/quote" component={Quote}/>

        <Route exact path="/polythylene-granules" component={Polythylene}/>
          <Route exact path="/hdpe-granules" component={Hdpe}/>
          <Route exact path="/ldpe-granules" component={Ldpe}/>
          <Route exact path="/lldpe-granules" component={Lldpe}/>

        <Route exact path="/polypropylene-granules" component={Polypropylene}/>
          
        <Route exact path="/ppcp-granules" component={Ppcp}/>

        <Route exact path="/abs-granules" component={Abs}/>
          

        <Route exact path="/polycarbonate-granules" component={Polycarbonate}/>
          
        <Route exact path="/tpe-granules" component={Tpe}/>
        <Route exact path="/hips-granules" component={Hips}/>
        <Route exact path="/hm-granules" component={Hm}/>
        <Route exact path="/nylon" component={Nylon}/>
         

        <Route exact path="/acrylic" component={Acrylic}/>
          



        {/* <Route path="/" element={<Main />}></Route> */}
        {/* <Route path='/about' element={<About/>}></Route> */}
        <Route component={Error}/>
      </Switch>
      <Footer/>
    </BrowserRouter>
  )
}

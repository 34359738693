import React from 'react';
import { NavLink } from 'react-router-dom'
import Enquiry from './sidebar/Enquiry'
import Rightsidebar from './sidebar/Rightsidebar'


function Hips() {
    return (
        <React.Fragment>
            <div>
                {/* <!-- Page Banner Area Start --> */}
                <div class="page-banner-area section overlay gradient">
                    <div class="container">
                        <div class="row">
                            <div class="page-banner col-12">
                                <h2>product</h2>
                                <ul>
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><NavLink to="product">Products</NavLink></li>
                                    <li><NavLink to="/hips-granules">HIPS Granules</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Page Banner Area End --> */}

                {/* Description Starts */}

                <div id="project-area" class="project-area section pt-120 pb-70">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-9 col-lg-8 col-12 mb-50">
                                <div class="single-project-details">
                                    <img class="main-image" src="assets/img/products/hips-granules.jpg" alt="" />
                                    <h3 class="title">HIPS Granules</h3>
                                    {/* <div class="tags fix">
                                    <a href="#">Energy</a>
                                    <a href="#">Green</a>
                                    <a href="#">Solar</a>
                                </div> */}
                                    {/* <!--  Content  --> */}
                                    <p>We bring forth quality Recycled HIPS Granules that are highly appreciated by the clients for their qualitative features. These Recycled HIPS Granules have several advantages over other polymers because of their clarity and ease of processing. Further, we offer Recycled HIPS Granules in proper packaging in order to avoid any damage during transit.</p>


                                    <h3 class="title">Features</h3>
                                    <ol>
                                        <li>High Stiffness</li>
                                        <li>High Specific Gravity</li>
                                        <li>Good Dimensional Stability</li>
                                        <li>Excellent Properties</li>
                                        
                                    </ol>



                                    <h3 class="title">Applications</h3>
                                    <ol>
                                        <li>Food Packaging</li>
                                        <li>In Manufacturing</li>
                                        <ol>
                                            <li>House Wares</li>
                                            <li>Audio/Video cassettes</li>
                                            <li>Office Products</li>
                                            <li>Body of TVS and Radios</li>
                                        </ol>
                                    </ol>



                                </div>




                                <Enquiry />
                            </div>
                            <Rightsidebar />
                        </div>
                    </div>
                </div>


            </div>
        </React.Fragment>
    );
}

export default Hips;

import './Header.css'
import './Test.css'
import { NavLink, useLocation } from 'react-router-dom'
import react, { Component, useEffect } from 'react'

const Header = () => {
    let timeout;
    let scroll = 0;
    let header = "header";
    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        window.onscroll = () => {
            if (scroll > 10) {
                document.getElementById('header').classList.add('is-sticky');
            } else {
                document.getElementById('header').classList.remove('is-sticky');
            }
            console.log('scroll==>', scroll, 'window.scrollY==>', window.scrollY);
            scroll = window.scrollY
        }
        onTop()
    }, [routePath])

    return (
        <react.Fragment>
            {/* Header Area Start */}
            <div id="header-area" className="header-area section">
                {/* Header Top Start */}
                <div className="header-top">
                    <div className="container">
                        <div className="row justify-content-between">
                            {/* Header Top Left */}
                            <div className="header-top-left col-md-auto col-12">
                                <p><span>Phone:</span> +91 8000906677</p>
                                <p><span>Email:</span> crystalpoly2017@gmail.com</p>
                            </div>
                            {/* Header Top Right */}
                            <div className="header-top-right col-md-auto col-12 d-none d-md-flex">
                                {/* Header Social */}
                                {/* <div className="header-social d-md-none d-lg-flex">
                                    <NavLink to="/"><i className="fa fa-facebook" /></NavLink>
                                    <NavLink to="/"><i className="fa fa-rss" /></NavLink>
                                    <NavLink to="/"><i className="fa fa-google" /></NavLink>
                                    <NavLink to="/"><i className="fa fa-pinterest-p" /></NavLink>
                                    <NavLink to="/"><i className="fa fa-instagram" /></NavLink>
                                </div> */}
                                {/* Header Quote Button */}
                                {/* <a href="contact.html" className="get-quote">get a qoute</a> */}
                                <NavLink to="/quote" className="get-quote">get a quote</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Header Top End */}
                {/* Header Bottom Start */}
                <div className="header-bottom" id={header}>
                    <div className="container">
                        <div className="row">
                            {/* Logo */}
                            <div className="header-logo col align-self-center">
                                {/* <a className="logo" href="index.html"> */}
                                <NavLink to="/">
                                    <img src="/assets/img/logo_final.png" alt="" /></NavLink>
                                {/* </a> */}
                            </div>
                            {/* Main Menu */}
                            <div id="main-menu" className="main-menu col-auto d-none d-lg-block">
                                <nav>
                                    <ul>

                                        <li><NavLink to="/">Home</NavLink></li>
                                        <li> <NavLink to="/product">Products</NavLink>
                                            <ul>
                                                <li className='dropdown-submenu'>
                                                    <NavLink to="/polythylene-granules" tabIndex={-1}>Polythylene Granules</NavLink>
                                                    <ul className='dropdown-menu'>
                                                        <li>
                                                            <NavLink to="/hdpe-granules" tabIndex={-1}>HDPE</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/ldpe-granules">LDPE</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/lldpe-granules">LLDPE</NavLink>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className='dropdown-submenu'>
                                                    <NavLink to="/polypropylene-granules" tabIndex={-1}>Polypropylene Granules</NavLink>
                                                    {/* <ul className='dropdown-menu'>
                                                        <li>
                                                            <NavLink to="/pp-milky-granules" tabIndex={-1}>PP Milky</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/pp-natural-granules">PP Natural</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/pp-plain-black-granules">PP Plain Black</NavLink>
                                                        </li> 
                                                        </ul>*/}
                                                </li>
                                                <li><NavLink to="/ppcp-granules">PPCP Granules</NavLink></li>
                                                <li className='dropdown-submenu'>
                                                    <NavLink to="/abs-granules" tabIndex={-1}>ABS Granules</NavLink>
                                                    {/* <ul className='dropdown-menu'>
                                                        <li>
                                                            <NavLink to="/abs-milky-granules" tabIndex={-1}>ABS Milky</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/abs-natural-granules">ABS Natural</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/abs-red-granules">ABS red</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/abs-cherry-granules">ABS Cherry</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/abs-black-granules">ABS Black</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/abs-brown-granules">ABS Brown</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/abs-grey-granules">ABS Grey</NavLink>
                                                        </li>
                                                    </ul> */}
                                                </li>
                                                <li className='dropdown-submenu'>
                                                    <NavLink to="/polycarbonate-granules" tabIndex={-1}>Polycarbonate Granules</NavLink>
                                                    {/* <ul className='dropdown-menu'>
                                                        <li>
                                                            <NavLink to="/pc-natural-granules" tabIndex={-1}>PC Natural</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/pc-black-granules">PC Black</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/pc-milky-granules">PC Milky</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/pc-grey-granules">PC Grey</NavLink>
                                                        </li>
                                                    </ul> */}
                                                </li>
                                                <li><NavLink to="/tpe-granules">TPE Granules</NavLink></li>
                                                <li><NavLink to="/hips-granules">HIPS Granules</NavLink></li>
                                                <li><NavLink to="/hm-granules">HM Granules</NavLink></li>
                                                <li className='dropdown-submenu'>
                                                    <NavLink to="/nylon" tabIndex={-1}>Nylon</NavLink>
                                                    {/* <ul className='dropdown-menu'>
                                                        <li>
                                                            <NavLink to="/nylon-6" tabIndex={-1}>Nylon6</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/nylon-66">Nylon66</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/nylon-plain">Nylon Plain</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/nylon-glass-filled">Nylon Glass Filled</NavLink>
                                                        </li>
                                                    </ul> */}
                                                </li>
                                                <li className='dropdown-submenu'>
                                                    <NavLink to="/acrylic" tabIndex={-1}>Acrylic</NavLink>
                                                    {/* <ul className='dropdown-menu'>
                                                        <li>
                                                            <NavLink to="/ac-red-granules" tabIndex={-1}>Acrylic Red</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/ac-umber-granules">Acrylic Umber</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/ac-black-granules">Acrylic Black</NavLink>
                                                        </li>
                                                        
                                                    </ul> */}
                                                </li>
                                            </ul>
                                        </li>
                                        <li> <NavLink to="/gallery">Gallery</NavLink></li>
                                        <li> <NavLink to="/about">About</NavLink></li>
                                        <li> <NavLink to="/contact">Contact</NavLink></li>
                                    </ul>
                                </nav>
                            </div>
                            {/* Header Search Wrapper */}
                            {/* Mobile Menu */}
                            <div className="mobile-menu col-12 d-lg-none" />
                        </div>
                    </div>
                </div>
                {/* Header Bottom End */}
            </div>
            {/* Header Area End */}
        </react.Fragment>
    )
}

export default Header;
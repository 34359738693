import React from 'react';
import { NavLink }from 'react-router-dom'
import Enquiry from './sidebar/Enquiry'
import Rightsidebar from './sidebar/Rightsidebar'


function Polycarbonate() {
  return (
      <React.Fragment>
          <div>
                {/* <!-- Page Banner Area Start --> */}
                <div class="page-banner-area section overlay gradient">
                    <div class="container">
                        <div class="row">
                            <div class="page-banner col-12">
                                <h2>product</h2>
                                <ul>
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><NavLink to="product">Products</NavLink></li>
                                    <li><NavLink to="/polycarbonate-granules">Polycarbonate Granules</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Page Banner Area End --> */}

                {/* Description Starts */}

                <div id="project-area" class="project-area section pt-120 pb-70">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-9 col-lg-8 col-12 mb-50">
                                <div class="single-project-details">
                                    <img class="main-image" src="assets/img/products/polycarbonate.jpg" alt="" />
                                    <h3 class="title">Polycarbonate Granules</h3>
                                    {/* <div class="tags fix">
                                    <a href="#">Energy</a>
                                    <a href="#">Green</a>
                                    <a href="#">Solar</a>
                                </div> */}
                                    {/* <!--  Content  --> */}
                                    <p>Polycarbonate Granules offered by us are manufactured using latest technology. These Recycled Polycarbonate Granules find application in various industries due to their superior quality. Available in different specifications, our Polycarbonate Granules can be customized as per clients' specifications. The various qualitative features of the Polycarbonate Granules together with their competitive price are the main reasons for their high demand in the market.</p>

                                    <h3 class="title">About Polycarbonate</h3>
                                    <p>Polycarbonate (PC) is a linear polycarbonic acid ester prepared from a dihydric phenol. Polycarbonate possesses extraordinarily good dimensional stability with a high impact strength which is maintained over a wide temperature range. This makes PC ideal for the manufacture of laboratory safety shields, vacuum desiccators and centrifuge tubes.</p>


                                    <h3 class="title">Polycarbonate Resistance</h3>
                                    <ol>
                                        <li>Excellent resistance (no attack) to dilute acids and mineral oils</li>
                                        <li>Good resistance (minor attack) to Alcohols and vegetable oils</li>
                                        <li>Limited resistance (moderate attack and suitable for short term use only) to Aldehydes</li>
                                        <li>Poor resistance (not recommended for use) with Concentrated Acids, Bases, Esters, Aliphatic Hydrocarbons, Aromatic Hydrocarbons, Halogenated Hydrocarbons, Ketones and Oxidizing Agents</li>
                                    </ol>

                                    <h3 class="title">Polycarbonate Fabrication</h3>
                                    <ol>
                                        <li>Polycarbonate is one of the toughest transparent sheets available</li>
                                        <li>Perfect for guards and glazing</li>
                                        <li>Polycarb machines well and brake forms cold</li>
                                        <li>Can be joined mechanically, solvent bonded, and welded with lots of practice</li>
                                        <li>Typical uses include windows, machine guards, and demonstration shields</li>


                                    </ol>

                                    <h3 class="title">Features</h3>
                                    <ol>
                                        <li>Strong</li>
                                        <li>Stiff</li>
                                        <li>Hard</li>
                                        <li>Transparent</li>
                                    </ol>

                                    <h3 class="title">Quick Facts</h3>
                                    <ol>
                                        <li>Maximum Temperature : 250°F 121°C</li>
                                        <li>Autoclavable : Yes</li>
                                        <li>Tensile Strength : 10000 psi</li>
                                        <li>UV Resistance : Good</li>
                                        <li>Rigid</li>
                                        <li>Minimum Temperature : -40°F -40°C</li>
                                        <li>Melting Point : 300°F 149°C</li>
                                        <li>Hardness : R75</li>
                                        <li>Clear</li>
                                        <li>Specific Gravity : 1.20</li>
                                    </ol>

                                </div>

                                {/* PRODUCT TYPE AREA */}
                                
                                <div id="project-area" class="project-area section pt-120 pb-90">
                                <h3 class="title">Types of Polycarbonate Granules</h3>

                                    <div class="container">
                                        <div class="row">
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/polycarbonate-natural.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">PC Natural</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/* <div class="tags fix"> 
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                    </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/pc-black.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">PC Black</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/*<div class="tags fix">
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                    </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/pc-grey.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">PC Grey</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/*<div class="tags fix">
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            {/* <!--  Single Project  --> */}
                                            <div class="col-lg-4 col-md-6 col-12 mb-30">
                                                
                                                <div class="single-project">
                                                    {/* <!--  Image  --> */}
                                                    <img src="assets/img/products/PC-Milky-Granules.jpg" alt="" />
                                                    {/* <!--  Title  --> */}
                                                    <h4><a href="project-details.html">PC Milky</a></h4>
                                                    {/* <!--  Tags  --> */}
                                                    {/*<div class="tags fix">
                                                        <a href="#">Energy</a>
                                                        <a href="#">Green</a>
                                                        <a href="#">Solar</a>
                                                </div>*/}
                                                    {/* <!--  Content  --> */}
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClitock,</p>
                                                    {/* <!--  Button  --> */}
                                                    {/* <a class="button" href="project-details.html">Learn More</a> */}
                                                </div>
                                            </div>
                                            
                                            
                                           
                                           
                                            
                                        </div>
                                    </div>
                                </div>

                                <Enquiry />
                            </div>
                            <Rightsidebar />
                        </div>
                    </div>
                </div>

                
            </div>
      </React.Fragment>
  );
}

export default Polycarbonate;

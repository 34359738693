import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Product() {
    return (
        <div>
            {/* <!-- Page Banner Area Start --> */}
            <div class="page-banner-area section overlay gradient">
                <div class="container">
                    <div class="row">
                        <div class="page-banner col-12">
                            <h2>Products </h2>
                            <ul>
                                <li><NavLink to="/">Home</NavLink></li>
                                <li><NavLink to="/product">Products</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Page Banner Area End --> */}

            {/* <!-- Project Area Start --> */}
            <div id="project-area" class="project-area section pt-120 pb-90">
                <div class="container">
                    <div class="row">
                        {/* <!--  Single Project  --> */}
                        <div class="col-lg-4 col-md-6 col-12 mb-30">
                            <div class="single-project">
                                {/* <!--  Image  --> */}
                                <img src="assets/img/products/hdpe.jpg" alt="" />
                                {/* <!--  Title  --> */}
                                <h4><a href="/polythylene-granules">Polythylene Granules</a></h4>
                                                                
                                {/* <!--  Content  --> */}
                                <p>Polyethylene Granules is a versatile, thermoplastic commodity extensively used in consumer products and food industry. Polyethylene Granules are created through polymerization of ethene.</p>
                                {/* <!--  Button  --> */}
                                <a class="button" href="/polythylene-granules">Learn More</a>
                            </div>
                        </div>
                        {/* <!--  Single Project  --> */}
                        <div class="col-lg-4 col-md-6 col-12 mb-30">
                            <div class="single-project">
                                {/* <!--  Image  --> */}
                                <img src="assets/img/products/Polyprolylene.jpg" alt="" />
                                {/* <!--  Title  --> */}
                                <h4><a href="/polypropylene">Polypropylene Granules</a></h4>
                                <p>Polypropylene (PP), a polymer prepared catalytically from propylene which differs from HDPE by having an isostatic replacement of a hydrogen atom by a methyl group on alternate carbon atoms in the main chain</p>
                                {/* <!--  Content  --> */}
                                {/* <!--  Button  --> */}
                                <a class="button" href="/polypropylene">Learn More</a>
                            </div>
                        </div>
                        {/* <!--  Single Project  --> */}
                        <div class="col-lg-4 col-md-6 col-12 mb-30">
                            <div class="single-project">
                                {/* <!--  Image  --> */}
                                <img src="assets/img/products/ppcp.jpg" alt="" />
                                {/* <!--  Title  --> */}
                                <h4><a href="/ppcp">PPCP Granules</a></h4>
                                
                                {/* <!--  Content  --> */}
                                <p>PPCP Granules are used on an industry-wide basis. It has high melting point and it is maintainance free. It has excellent electrical properties.</p>
                                {/* <!--  Button  --> */}
                                <a class="button" href="/ppcp">Learn More</a>
                            </div>
                        </div>
                        {/* <!--  Single Project  --> */}
                        <div class="col-lg-4 col-md-6 col-12 mb-30">
                            <div class="single-project">
                                {/* <!--  Image  --> */}
                                <img src="assets/img/products/abs-grey.jpg" alt="" />
                                {/* <!--  Title  --> */}
                                <h4><a href="/abs">ABS Granules</a></h4>
                                
                                <p>Acrylonitrile Butadiene Styrene (ABS) is formed by the polymerization of Acrylonitrile, Butadiene, and Styrene monomers.  ABS possesses outstanding impact strength and high mechanical strength, which makes it so suitable for tough consumer products.</p>
                                {/* <!--  Button  --> */}
                                <a class="button" href="/abs">Learn More</a>
                            </div>
                        </div>
                        {/* <!--  Single Project  --> */}
                        <div class="col-lg-4 col-md-6 col-12 mb-30">
                            <div class="single-project">
                                {/* <!--  Image  --> */}
                                <img src="assets/img/products/polycarbonate-natural.jpg" alt="" />
                                {/* <!--  Title  --> */}
                                <h4><a href="/polycarbonate">Polycarbonate Granules</a></h4>
                                
                                {/* <!--  Content  --> */}
                                <p>Polycarbonate (PC) is a linear polycarbonic acid ester prepared from a dihydric phenol. Polycarbonate possesses extraordinarily good dimensional stability with a high impact strength which is maintained over a wide temperature range.</p>
                                {/* <!--  Button  --> */}
                                <a class="button" href="/polycarbonate">Learn More</a>
                            </div>
                        </div>
                        {/* <!--  Single Project  --> */}
                        <div class="col-lg-4 col-md-6 col-12 mb-30">
                            <div class="single-project">
                                {/* <!--  Image  --> */}
                                <img src="assets/img/products/tpe.jpg" alt="" />
                                {/* <!--  Title  --> */}
                                <h4><a href="/tpe">TPE Granules</a></h4>
                                
                                {/* <!--  Content  --> */}
                                <p>Thermoplastic Elastomers (TPE) - TPEs are a family of polymers that can be repeatedly stretched without permanently deforming the shape of the part. Unlike rubber-like elastomers, they do not require curing or vulcanization, as they are true thermoplastics.</p>
                                {/* <!--  Button  --> */}
                                <a class="button" href="/tpe">Learn More</a>
                            </div>
                        </div>
                        {/* <!--  Single Project  --> */}
                        <div class="col-lg-4 col-md-6 col-12 mb-30">
                            <div class="single-project">
                                {/* <!--  Image  --> */}
                                <img src="assets/img/products/hips-granules.jpg" alt="" />
                                {/* <!--  Title  --> */}
                                <h4><a href="/hips">HIPS Granules</a></h4>
                                
                                {/* <!--  Content  --> */}
                                <p>HIPS Granules have several advantages over other polymers because of their clarity and ease of processing. HIPS Granules has high stiffness, high specific gravity, good dimensional stability.</p>
                                {/* <!--  Button  --> */}
                                <a class="button" href="/hips">Learn More</a>
                            </div>
                        </div>
                        {/* <!--  Single Project  --> */}
                        <div class="col-lg-4 col-md-6 col-12 mb-30">
                            <div class="single-project">
                                {/* <!--  Image  --> */}
                                <img src="assets/img/products/hm-granules-500x500.jpg" alt="" />
                                {/* <!--  Title  --> */}
                                <h4><a href="/hm">HM Granules</a></h4>
                                
                                {/* <!--  Content  --> */}
                                <p>Recycled HM Granules are widely demanded by various industries owing to their qualitative features. It is used in Liners, Carry bags, Tarpaulins and Packaging Items.</p>
                                {/* <!--  Button  --> */}
                                <a class="button" href="/hm">Learn More</a>
                            </div>
                        </div>
                        {/* <!--  Single Project  --> */}
                        <div class="col-lg-4 col-md-6 col-12 mb-30">
                            <div class="single-project">
                                {/* <!--  Image  --> */}
                                <img src="assets/img/products/nylon-6.jpg" alt="" />
                                {/* <!--  Title  --> */}
                                <h4><a href="/nylon">Nylon Granules</a></h4>
                                
                                {/* <!--  Content  --> */}
                                <p>Nylon Granules have high strength and hardness, low calorie friction, high dimensional stability, high wear and abraction resistance and high melting point</p>
                                {/* <!--  Button  --> */}
                                <a class="button" href="/nylon">Learn More</a>
                            </div>
                        </div>
                        {/* <!--  Single Project  --> */}
                        <div class="col-lg-4 col-md-6 col-12 mb-30">
                            <div class="single-project">
                                {/* <!--  Image  --> */}
                                <img src="assets/img/products/acrylic-granules.jpg" alt="" />
                                {/* <!--  Title  --> */}
                                <h4><a href="/acrylic">Acrylic Granules</a></h4>
                                
                                {/* <!--  Content  --> */}
                                <p>Nylon Granules have high strength and hardness, low calorie friction, high dimensional stability, high wear and abraction resistance and high melting point</p>
                                {/* <!--  Button  --> */}
                                <a class="button" href="/acrylic">Learn More</a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            {/* <!-- Project Area End --> */}
        </div>
    )
}

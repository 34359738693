import React from 'react';
import { NavLink } from 'react-router-dom'
import Enquiry from './sidebar/Enquiry'
import Rightsidebar from './sidebar/Rightsidebar'



function Ppcp() {
    return (
        <React.Fragment>
            <div>
                {/* <!-- Page Banner Area Start --> */}
                <div class="page-banner-area section overlay gradient">
                    <div class="container">
                        <div class="row">
                            <div class="page-banner col-12">
                                <h2>product</h2>
                                <ul>
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><NavLink to="/product">Products</NavLink></li>
                                    <li><NavLink to="/ppcp-granules">PPCP</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Page Banner Area End --> */}

                {/* Description Starts */}

                <div id="project-area" class="project-area section pt-120 pb-70">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-9 col-lg-8 col-12 mb-50">
                                <div class="single-project-details">
                                    <img class="main-image" src="assets/img/products/ppcp.jpg" alt="" />
                                    <h3 class="title">PPCP Granules</h3>
                                    {/* <div class="tags fix">
                                    <a href="#">Energy</a>
                                    <a href="#">Green</a>
                                    <a href="#">Solar</a>
                                </div> */}
                                    {/* <!--  Content  --> */}
                                    <p>Avail from us a range of PPCP Granules, which has no alternative in the market for its quality. Manufactured using high grade plastic raw material, the PPCP Granules are offered by us in various shapes and sizes, depending upon the requirement of various applications. Known for their excellent properties, the PPCP Granules are used on an industry-wide basis.</p>



                                    <h3 class="title">PPCP Strength</h3>
                                    <ol>
                                        <li>High impact resistance</li>
                                        <li>More pliable than homopolymer</li>
                                        <li>Meets food handling guidelines</li>
                                        <li>Chemical- and corrosion resistant</li>
                                        <li>Better resistance to cracking at low temperatures than other materials</li>
                                        <li>No moisture absorption</li>
                                        
                                    </ol>

                                    <h3 class="title">Applications</h3>
                                    <ol>
                                        <li>Die cutting pads Fabricated parts/living hinge parts</li>
                                        <li>Fire truck water and foam tanks Orthotic and prosthetic devices</li>
                                        <li>Plating and anodizing process equipment Tanks - secondary containment</li>

                                    </ol>


    
                                    

                                </div>

                                

                                <Enquiry />
                            </div>
                            <Rightsidebar />
                        </div>
                    </div>
                </div>


            </div>
        </React.Fragment>
    );
}

export default Ppcp;

import React from 'react'
import PropTypes from 'prop-types'
// CSS
import classes from './Subtitle.module.css'

const subtitle = (props) => {
    return (
        <p className={classes.Container}>
            {props.children}
        </p>
    )
}

subtitle.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
}

export default subtitle

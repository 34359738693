import React from 'react';
import { NavLink }from 'react-router-dom'


function Polythylene() {
  return (
      <React.Fragment>
          <div>
            {/* <!-- Page Banner Area Start --> */}
            <div class="page-banner-area section overlay gradient">
                <div class="container">
                    <div class="row">
                        <div class="page-banner col-12">
                            <h2>product</h2>
                            <ul>
                                <li><NavLink to="/">Home</NavLink></li>
                                <li><NavLink to="product">Products</NavLink></li>
                                <li><NavLink to="/polythylene-granules">Polythylene Granules</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Page Banner Area End --> */}

            {/* <!-- Project Area Start --> */}
            <div id="project-area" class="project-area section pt-120 pb-90">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12 mb-30">
                            <h1>Polythylene Granules</h1>
                            <p>
                            Polyethylene Granules is a versatile, thermoplastic commodity extensively used in consumer products and food industry. Polyethylene Granules are created through polymerization of ethene. HDPE Granules, LDPE Granules and LLDPE Granules are made available by us in this range. Use of the finest raw material and high-end technology in manufacturing the Polyethylene Granules ensures the best quality in all aspects and thus the best results in application. We can offer the range of Polyethylene Granules in bulk at anytime and that too at the most affordable rate to the clients.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-12 mb-30">
                            <div class="single-project">
                                <img src="assets/img/products/hdpe.jpg" alt="" />
                                <h4><NavLink to="/hdpe-granules">HDPE Granules</NavLink></h4>
                                {/* <div class="tags fix">
                                    <NavLink to="#">Energy</NavLink>
                                    <NavLink to="#">Green</NavLink>
                                    <NavLink to="#">Solar</NavLink>
                                </div> */}
                                <p>We offer high quality HDPE Granules that is a multi-purpose polyethylene used in diverse industrial sectors for specific applications. These HDPE Granules are sourced from reliable manufacturers and is highly appreciated by clients for superior quality. We provide the HDPE Granules at nominal rates to clients.</p>
                                <NavLink class="button" to="/hdpe-granules">Learn More</NavLink>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12 mb-30">
                            <div class="single-project">
                                <img src="assets/img/products/ldpe.jpg" alt="" />
                                <h4><NavLink to="/ldpe-granules">LDPE Granules</NavLink></h4>
                                <p>We are engaged in offering very soft and pliable LDPE Granules for various applications. Also called thermoplastic, LDPE is a substantially linear polymer (polyethylene) made from petroleum. Moreover, our company supplies optimum quality Low Density Polyethylene Granules at market leading rates.</p>
                                <NavLink class="button" to="/ldpe-granules">Learn More</NavLink>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12 mb-30">
                            <div class="single-project">
                                <img src="assets/img/products/lldpe-granule.jpg" alt="" />
                                <h4><NavLink to="/lldpe-granules">LLDPE Granules</NavLink></h4>
                                <p>We are involved in offering top quality LLDPE Granules. This range of Linear Low Density Polyethylene Granules has a large number of qualitative features, making it perfect for a multitude of applications across industries. We have marked our presence as one of the dominant LLDPE Granules Suppliers and Manufacturers in India.</p>
                                <NavLink class="button" to="/lldpe-granules">Learn More</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Project Area End --> */}
        </div>
      </React.Fragment>
  );
}

export default Polythylene;

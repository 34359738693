import React from 'react';
import { NavLink }from 'react-router-dom'
import Enquiry from './sidebar/Enquiry'
import Rightsidebar from './sidebar/Rightsidebar'


function Tpe() {
  return (
      <React.Fragment>
          <div>
                {/* <!-- Page Banner Area Start --> */}
                <div class="page-banner-area section overlay gradient">
                    <div class="container">
                        <div class="row">
                            <div class="page-banner col-12">
                                <h2>product</h2>
                                <ul>
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><NavLink to="product">Products</NavLink></li>
                                    <li><NavLink to="/tpe-granules">TPE Granules</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Page Banner Area End --> */}

                {/* Description Starts */}

                <div id="project-area" class="project-area section pt-120 pb-70">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-9 col-lg-8 col-12 mb-50">
                                <div class="single-project-details">
                                    <img class="main-image" src="assets/img/products/tpe-granules.jpg" alt="" />
                                    <h3 class="title">TPE Granules</h3>
                                    {/* <div class="tags fix">
                                    <a href="#">Energy</a>
                                    <a href="#">Green</a>
                                    <a href="#">Solar</a>
                                </div> */}
                                    {/* <!--  Content  --> */}
                                    <p>We are positioned amidst the major TPE Granules Manufacturers and Suppliers in India. Our expertise lies in providing a variety of TPE Granules in various profiles and sizes as per the requirements of the clients from various industries. TPE Granules offered by this have high resistance against heat, weather, UV, chemicals and Ozone.</p>

                                    <h3 class="title">About TPE</h3>
                                    <p>Thermoplastic Elastomers (TPE) - TPEs are a family of polymers that can be repeatedly stretched without permanently deforming the shape of the part. Unlike rubber-like elastomers, they do not require curing or vulcanization, as they are true thermoplastics. Thermoplastic elastomers (TPEs) may be processed by conventional thermoplastic techniques such as injection molding, extrusion and blow molding. Thermoplastic elastomers have replaced rubber in many applications, most importantly the automotive industry. There are six main thermoplastic elastomer groups found commercially; styrenic block copolymers, polyolefin blends (TPOs), elastomeric alloys, thermoplastic polyurethanes (TPUs), thermoplastic copolyesters and thermoplastic polyamides.</p>


                                    <h3 class="title">WHY TPE</h3>
                                    <ol>
                                        <li>Good Flexibility</li>
                                        <li>Resistance</li>
                                        <li>Good Colorability</li>
                                        <li>Good Adhesion</li>
                                        <li>Recyclable Material</li>
                                        <li>Good Processability</li>
                                    </ol>

                                    

                                    <h3 class="title">Applications</h3>
                                    <ol>
                                        <li>Sporting Goods</li>
                                        <li>Baskets</li>
                                        <li>Medical/Healthcare Application</li>
                                        <li>Tubing</li>
                                        <li>Consumer Goods</li>
                                        <li>General Purpose Goods</li>
                                        <li>Automotive Interior and Exterior Parts</li>
                                    </ol>

                                    

                                </div>

                                
                                

                                <Enquiry />
                            </div>
                            <Rightsidebar />
                        </div>
                    </div>
                </div>

                
            </div>
      </React.Fragment>
  );
}

export default Tpe;

import React from 'react';
import { NavLink } from 'react-router-dom'
import Enquiry from './sidebar/Enquiry'
import Rightsidebar from './sidebar/Rightsidebar'


function Hm() {
    return (
        <React.Fragment>
            <div>
                {/* <!-- Page Banner Area Start --> */}
                <div class="page-banner-area section overlay gradient">
                    <div class="container">
                        <div class="row">
                            <div class="page-banner col-12">
                                <h2>product</h2>
                                <ul>
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><NavLink to="product">Products</NavLink></li>
                                    <li><NavLink to="/hm-granules">HM Granules</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Page Banner Area End --> */}

                {/* Description Starts */}

                <div id="project-area" class="project-area section pt-120 pb-70">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-9 col-lg-8 col-12 mb-50">
                                <div class="single-project-details">
                                    <img class="main-image" src="assets/img/products/hm-granules-500x500.jpg" alt="" />
                                    <h3 class="title">HM Granules</h3>
                                    {/* <div class="tags fix">
                                    <a href="#">Energy</a>
                                    <a href="#">Green</a>
                                    <a href="#">Solar</a>
                                </div> */}
                                    {/* <!--  Content  --> */}
                                    <p>These Recycled HM Granules are widely demanded by various industries owing to their qualitative features. Moreover, the Recycled HM Granules are stringently checked on various parameters before making a final dispatch to the clients.</p>

                                    <h3 class="title">Features</h3>
                                    <ol>
                                        <li>High Stiffness</li>
                                        <li>High Specific Gravity</li>
                                        <li>Good Dimensional Stability</li>
                                        <li>Excellent Properties</li>
                                        
                                    </ol>



                                    <h3 class="title">Applications</h3>
                                    <ol>
                                        <li>Carry Bags</li>
                                        <li>Liners</li>
                                        <li>Tarpaulins</li>
                                        <li>Packaging Items</li>
                                    </ol>



                                </div>




                                <Enquiry />
                            </div>
                            <Rightsidebar />
                        </div>
                    </div>
                </div>


            </div>
        </React.Fragment>
    );
}

export default Hm;
